import React from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import { resetAllPeripherals } from "../../DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";
import {
    resetMoneticData,
    setMoneticData,
    store as moneticDataStore,
} from "./store";
import {
    store as generalStore,
    setIsActionsClicked,
    setActions,
} from "../../store";

import { ipMatchWord, numberMatchWord } from "../../../../constants";
import { resetPrinterData } from "../PrinterPeripheralConfigure/store";
import { resetCashManagementData } from "../CashManagementPeripheralConfigure/store";
import { verifyIfPeripheralAssociate } from "../../../../helpers/peripheralsHelpers";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { checkIpIfExist } from "@helpers/general";

type FooterType = {
    setDataIsUpdated: Function;
    editedData: any;
    selectedRows: any;
    isAddPeripheralClicked: boolean;
    isEdited: boolean;
    setIsAddPeripheralClicked: Function;
    setEditedData: Function;
    setIsEdited: Function;
    setIsConfirmationEditModalOpened: Function;
    shopIds: any;
    isConsult: any;
    setIsConsult: Function;
};

export function CardFooter({
    setEditedData,
    setDataIsUpdated,
    editedData,
    selectedRows,
    isAddPeripheralClicked,
    isEdited,
    setIsAddPeripheralClicked,
    setIsEdited,
    setIsConfirmationEditModalOpened,
    shopIds,
    isConsult,
    setIsConsult,
}: FooterType) {
    const { t } = useTranslation();
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const { franchiseID, userID } = useSnapshot(store);
    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const { isActionsClicked } = useSnapshot(generalStore);
    const { oneShop, shopID, resourceIp, operatorID } = useSnapshot(userStore);
    const shopId = oneShop ? shopID : selectedRows?.shopId;
    const appId = "KIOSK";
    const uuidUser = localStorage.getItem("uuidUser");

    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const {
        designation,
        mark,
        reference,
        note,
        merchantCode,
        paymentType,
        url,
        port,
        speed,
        ipAddress,
        isNameExist,
        deviceCode,
        kiss,
        typeConfig,
        isModalOpened,
        nomTpe,
        typeTerminal,
        timeout,
        inputTimeout,
        inputPaymentMethod,
        paymentMethod,
        ipExist,
        emptyIp,
        webhook,
        adyenIpAddress,
        mode,
        adyenIpExist,
        idIntegrator,
        autoValidate,
        currency,
    } = useSnapshot(moneticDataStore);
    function makeId(length: any) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }
    async function RedisPublishPeripheral() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: oneShop ? shopID : shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function addMonetics() {
        let hasIpConflict: number = 0;

        if (canValidate) {
            setCanValidate(false);

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/monetics`;
            let shop = oneShop ? [shopID] : shopIds;
            shop.forEach(async (element: any) => {
                let data = {
                    userId: userID,
                    shopId: element,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: [
                        {
                            id: makeId(10),
                            designation: designation.trim(),
                            mark: mark,
                            reference: reference,
                            note: note,
                            shopId: oneShop ? shopID : shopId,
                            merchant_code: merchantCode,
                            device_code: deviceCode,
                            port: port,
                            url: url,
                            speed: speed,
                            setup_type: typeConfig,
                            ip: ipAddress,
                            payment_type: paymentType,
                            payment_method: paymentMethod,
                            timeout: timeout,
                            kiss: kiss,
                            terminal_type: typeTerminal,
                            isArchived: false,
                            adyenIp: adyenIpAddress,
                            mode: mode,
                            webhook: webhook,
                            idIntegrator: idIntegrator,
                            autoValidate: autoValidate,
                            currency: currency,
                        },
                    ],
                };

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data),
                };
                let filteredData = JSON.parse(JSON.stringify(resourceIp))
                    ?.filter(
                        (item: any) =>
                            item?.shopId === element && item.ip.trim() !== ""
                    )
                    .map((y: any) => {
                        return y.ip;
                    });
                filteredData = [...new Set(filteredData)];
                if (filteredData.includes(ipAddress)) {
                    hasIpConflict++;
                }
                if (!filteredData.includes(ipAddress)) {
                    try {
                        mutate(
                            apiUrlAdd,
                            await fetch(apiUrlAdd, requestOptions)
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error("error");
                                    }
                                })
                        );
                    } catch (e: any) {
                        setCanValidate(true);
                        setError(true);
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
            });
            if (hasIpConflict > 0) {
                ErrorToast(
                    t(
                        "Please note, the device could not be added to one or more stores. Please check the IP address settings."
                    )
                );
            }
            if (error === false) {
                toast.success(`${t("The device is successfully added")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });

                resetMoneticData();
                setDataIsUpdated!(true);
                resetAllPeripherals();
                resetPrinterData();
                resetCashManagementData();
                resetMoneticData();
                setEditedData([]);
                setIsEdited(false);
                activateCheckbox();
                setCanValidate(true);
            }
        }
    }
    async function editMonetics() {
        if (canValidate) {
            setCanValidate(false);

            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                editedData.id_monetic,
                shopId,
                franchiseID,
                userID,
                appId
            );

            const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/monetics/configuration?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;

            let dataConfig = {
                userId: userID,
                shopId: shopId,
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: editedData.id_monetic,
                data: [
                    {
                        id: editedData.id_monetic,
                        designation: designation.trim(),
                        tpe_name: nomTpe,
                        reference: reference,
                        note: note,
                        android: "android",
                        merchant_code: merchantCode,
                        speed: speed,
                        device_code: deviceCode,
                        port: port,
                        url: url,
                        shopId: shopId,
                        kiss: kiss,
                        setup_type: typeConfig,
                        ip: ipAddress,
                        payment_type: paymentType,
                        mark: mark,
                        payment_method: paymentMethod,
                        timeout: timeout,
                        terminal_type: typeTerminal,
                        isArchived: false,
                        adyenIp: adyenIpAddress,
                        mode: mode,
                        webhook: webhook,
                        idIntegrator: idIntegrator,
                        autoValidate: autoValidate,
                        currency: currency,
                    },
                ],
            };

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataConfig),
            };

            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (
                                ifPeripheralAssociateObject.isPeripheralAssociate
                            )
                                setTimeout(RedisPublishPeripheral, 3000);
                            setCanValidate(true);
                            resetCashManagementData();
                            setDataIsUpdated!(true);
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                            toast.success(
                                `${t("The device was successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        })
                );
            } catch (e: any) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    React.useEffect(() => {
        setMoneticData(
            (shopIds.length > 1 && !oneShop) ||
                (shopIds.length === 0 && !oneShop)
                ? false
                : checkIpIfExist(
                      resourceIp,
                      ipAddress,
                      editedData?.peripheralId || "",
                      oneShop
                          ? shopID
                          : editedData?.shopId !== undefined
                          ? editedData?.shopId
                          : shopIds.length === 1
                          ? shopIds[0]
                          : ""
                  ),
            "ipExist"
        );
        setMoneticData(
            (shopIds.length > 1 && !oneShop) ||
                (shopIds.length === 0 && !oneShop)
                ? false
                : checkIpIfExist(
                      resourceIp,
                      adyenIpAddress,
                      editedData?.peripheralId || "",
                      oneShop
                          ? shopID
                          : editedData?.shopId !== undefined
                          ? editedData?.shopId
                          : shopIds.length === 1
                          ? shopIds[0]
                          : ""
                  ),
            "adyenIpExist"
        );
        // eslint-disable-next-line
    }, [shopIds, ipAddress, adyenIpAddress]);
    const handleValidate = () => {
        if (mark === "") {
            setMoneticData(true, "selectMark");
        }
        if (designation === "") {
            setMoneticData(true, "inputDesignation");
        }
        if (speed === "") {
            setMoneticData(true, "speedSelectInput");
        }
        if (port === "") {
            setMoneticData(true, "portSelectInput");
        }
        if (paymentType === "") {
            setMoneticData(true, "SelectInput");
        }
        if (ipAddress === "") {
            setMoneticData(true, "emptyIp");
        }

        if (!ipAddress.match(ipMatchWord)) {
            setMoneticData(true, "inputIp");
        }
        if (adyenIpAddress === "") {
            setMoneticData(true, "inputAdyenIpAddress");
        }
        if (webhook === "") {
            setMoneticData(true, "inputWebHook");
        }
        if (mode === "") {
            setMoneticData(true, "inputMode");
        }
        if (idIntegrator === "") {
            setMoneticData(true, "inputIdIntegrator");
        }
        if (autoValidate === "") {
            setMoneticData(true, "inputAutoValidate");
        }
        if (currency === "") {
            setMoneticData(true, "inputCurrency");
        }
        setMoneticData(
            (shopIds.length > 1 && !oneShop) ||
                (shopIds.length === 0 && !oneShop)
                ? false
                : checkIpIfExist(
                      resourceIp,
                      ipAddress,
                      editedData?.peripheralId || "",
                      oneShop
                          ? shopID
                          : editedData?.shopId !== undefined
                          ? editedData?.shopId
                          : shopIds.length === 1
                          ? shopIds[0]
                          : ""
                  ),
            "ipExist"
        );
        setMoneticData(
            (shopIds.length > 1 && !oneShop) ||
                (shopIds.length === 0 && !oneShop)
                ? false
                : checkIpIfExist(
                      resourceIp,
                      adyenIpAddress,
                      editedData?.peripheralId || "",
                      oneShop
                          ? shopID
                          : editedData?.shopId !== undefined
                          ? editedData?.shopId
                          : shopIds.length === 1
                          ? shopIds[0]
                          : ""
                  ),
            "adyenIpExist"
        );

        if (port === "") {
            setMoneticData(true, "inputPort");
        }
        if (merchantCode === "") {
            setMoneticData(true, "InputCode");
        }

        if (url === "") {
            setMoneticData(true, "InputUrl");
        }
        if (timeout === "") {
            setMoneticData(true, "inputTimeout");
        }

        if (
            paymentMethod !== undefined &&
            Object.keys(paymentMethod).length === 0
        ) {
            setMoneticData(true, "inputPaymentMethod");
        }
        if (Object.keys(typeConfig).length === 0) {
            setMoneticData(true, "inputSetupType");
        }
        if (Object.keys(typeTerminal).length === 0) {
            setMoneticData(true, "inputTerminalType");
        }
        if (editedData.id_monetic) {
            if (
                (mark === "Valina" &&
                    designation.length !== 0 &&
                    reference !== "" &&
                    port !== "" &&
                    speed !== "" &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    inputPaymentMethod === false &&
                    inputTimeout === false &&
                    timeout !== "" &&
                    timeout.match(numberMatchWord) &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (mark === "Nepting" &&
                    Object.keys(typeConfig).length > 0 &&
                    Object.keys(typeTerminal).length > 0 &&
                    designation.length !== 0 &&
                    reference !== "" &&
                    ipAddress !== "" &&
                    port !== "" &&
                    merchantCode !== "" &&
                    url !== "" &&
                    paymentType !== "" &&
                    ipExist === false &&
                    Number(port) &&
                    emptyIp === false &&
                    ipAddress.match(ipMatchWord) &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (mark === "Adyen" &&
                    designation.length !== 0 &&
                    ipAddress !== "" &&
                    emptyIp === false &&
                    port !== "" &&
                    webhook !== "" &&
                    ipExist === false &&
                    adyenIpExist === false &&
                    ipAddress.match(ipMatchWord) &&
                    adyenIpAddress.match(ipMatchWord) &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (mark === "Valina swiss" &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp) &&
                    idIntegrator !== "" &&
                    autoValidate !== "" &&
                    currency !== "")
            ) {
                if (isEdited === false) {
                    setMoneticData(!isModalOpened, "isModalOpened");
                    setIsConfirmationEditModalOpened(true);
                } else {
                    editMonetics();
                    setIsAddPeripheralClicked(!isAddPeripheralClicked);
                }
            }
        } else if (
            (mark === "Valina" &&
                designation.length !== 0 &&
                reference !== "" &&
                port !== "" &&
                speed !== "" &&
                !isNameExist &&
                designation.trim() !== "" &&
                inputPaymentMethod === false &&
                inputTimeout === false &&
                designation.match(notStartWithSpaceRegExp)) ||
            (mark === "Nepting" &&
                Object.keys(typeConfig).length > 0 &&
                Object.keys(typeTerminal).length > 0 &&
                designation.length !== 0 &&
                reference !== "" &&
                ipAddress !== "" &&
                emptyIp === false &&
                port !== "" &&
                merchantCode !== "" &&
                url !== "" &&
                paymentType !== "" &&
                ipExist === false &&
                Number(port) &&
                ipAddress.match(ipMatchWord) &&
                !isNameExist &&
                designation.trim() !== "" &&
                designation.match(notStartWithSpaceRegExp)) ||
            (mark === "Adyen" &&
                designation.length !== 0 &&
                ipAddress !== "" &&
                emptyIp === false &&
                port !== "" &&
                webhook !== "" &&
                ipExist === false &&
                adyenIpExist === false &&
                ipAddress.match(ipMatchWord) &&
                adyenIpAddress.match(ipMatchWord) &&
                !isNameExist &&
                designation.trim() !== "" &&
                designation.match(notStartWithSpaceRegExp)) ||
            (mark === "Valina swiss" &&
                designation.length !== 0 &&
                !isNameExist &&
                designation.trim() !== "" &&
                designation.match(notStartWithSpaceRegExp) &&
                idIntegrator !== "" &&
                autoValidate !== "" &&
                currency !== "" &&
                typeTerminal !== "")
        ) {
            if (shopId === undefined) {
                ErrorToast(`${t("Attention ! you must select a store.")!}`);
            } else {
                addMonetics();
            }
        }
    };
    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };

    return (
        <React.Fragment>
            <CustomSecondaryColorButton
                outline
                rounded
                variant="light"
                className="mr-2 afp_btn_FRcancel cmn_btn_cancel"
                onClick={() => {
                    if (isEdited === true) {
                        setIsActionsClicked(!isActionsClicked);
                        setActions(() => {
                            setIsAddPeripheralClicked(!isAddPeripheralClicked);
                            resetMoneticData();
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                        });
                    } else {
                        setIsAddPeripheralClicked(!isAddPeripheralClicked);
                        resetMoneticData();
                        resetAllPeripherals();
                        resetPrinterData();
                        resetCashManagementData();
                        resetMoneticData();
                        setEditedData([]);
                        setIsEdited(false);
                        activateCheckbox();
                    }
                    setIsConsult(false);
                }}
            >
                <span> {t("Cancel")}</span>
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                variant="primary"
                className={`afp_btn_FRvalidate cmn_btn_validate ${
                    isConsult ? "not-allowed-icon__clz" : ""
                }`}
                onClick={handleValidate}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </React.Fragment>
    );
}
