import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";

import { designStore } from "@store";
import { ConfigOptionsWrapper } from "@components/Common/Design/Preview/ConfigOptionsWrapper";
import { UsualNumericKeyboardWrapper } from "@components/Common/UsualNumericKeyboardWrapper";

import { ActionsButtons } from "./ActionsButtons";
import { TopBannerWrapper } from "./TopBannerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { PreviewBackgroundWrapper } from "./PreviewBackgroundWrapper";

export function AccountFidelityWrapper(): JSX.Element {
    const { t } = useTranslation();
    const {
        isConfigOptionsActive,
        isSubStepTopBannerActive,
        isSubStepInformationMessageActive,
        selectedSubStep,
        registerForm,
    } = useSnapshot(designStore);

    return (
        <PreviewBackgroundWrapper selectedSubStep={selectedSubStep}>
            <div
                style={{
                    gap: "10px",
                    position: "relative",
                    display: "grid",
                    gridTemplateRows:
                        "min-content min-content 1fr min-content min-content",
                    height: "100%",
                }}
            >
                {isSubStepTopBannerActive ? <TopBannerWrapper /> : null}
                {isSubStepInformationMessageActive ? <HeaderWrapper /> : null}
                {Object.values(registerForm).filter(
                    (item) => item.active === true
                ).length === 1 ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <UsualNumericKeyboardWrapper />
                    </div>
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ gap: "20px" }}
                    >
                        <AvForm
                            className="form-horizontal"
                            style={{ width: "100%" }}
                        >
                            {registerForm.phoneNumber.active ? (
                                <div
                                    className="mx-2 w-70"
                                    style={{ height: "55px" }}
                                >
                                    <label>{t("Numéro de téléphone")}</label>

                                    <img
                                        src="./images/phoneIput.PNG"
                                        alt="img"
                                    />
                                </div>
                            ) : null}
                            <div
                                className="d-flex align-items-center"
                                style={{
                                    gap: "10px",
                                    marginLeft: "5px",
                                    height: "55px",
                                }}
                            >
                                {registerForm.name.active ? (
                                    <div
                                        className="mx-2"
                                        style={{ width: "100%" }}
                                    >
                                        <label htmlFor="name">
                                            {t("name")}
                                        </label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            style={{ height: "25px" }}
                                        />
                                    </div>
                                ) : null}
                                {registerForm.firstName.active ? (
                                    <div
                                        className="mx-2 "
                                        style={{
                                            width: "100%",
                                            height: "55px",
                                        }}
                                    >
                                        <label htmlFor="firstName">
                                            {t("firstName")}
                                        </label>
                                        <input
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            className="form-control"
                                            style={{ height: "25px" }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {registerForm.email.active ? (
                                <div
                                    className="mx-2 w-70"
                                    style={{ height: "55px" }}
                                >
                                    <label htmlFor="email">{t("email")}</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        style={{ height: "25px" }}
                                    />
                                </div>
                            ) : null}
                            <div
                                className="d-flex align-items-center
                            "
                                style={{
                                    gap: "10px",
                                    marginLeft: "5px",
                                    height: "55px",
                                }}
                            >
                                {registerForm.country.active ? (
                                    <div
                                        className="mx-2"
                                        style={{ width: "100%" }}
                                    >
                                        <label htmlFor="country">
                                            {t("Country")}
                                        </label>
                                        <input
                                            id="country"
                                            name="country"
                                            type="text"
                                            className="form-control"
                                            style={{ height: "25px" }}
                                        />
                                    </div>
                                ) : null}
                                {registerForm.postalCode.active ? (
                                    <div
                                        className="mx-2"
                                        style={{
                                            width: "100%",
                                            height: "55px",
                                        }}
                                    >
                                        <label htmlFor="postalCode">
                                            {t("Postal code")}
                                        </label>
                                        <input
                                            id="postalCode"
                                            name="postalCode"
                                            type="text"
                                            className="form-control"
                                            style={{ height: "25px" }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {/* {registerForm.password.active ? (
                                <div
                                    className="d-flex flex-row align-items-center
                            justify-content-between"
                                    style={{
                                        marginLeft: "5px",
                                        height: "55px",
                                    }}
                                >
                                    <div
                                        className="mx-2"
                                        style={{ width: "100%" }}
                                    >
                                        <label htmlFor="password">
                                            {t("Password")}
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            className="form-control"
                                            style={{ height: "25px" }}
                                           
                                            value={inputEmail}
                                        />
                                    </div>

                                    <div
                                        className="mx-2"
                                        style={{ width: "100%" }}
                                    >
                                        <label htmlFor="cpassword">
                                            {t("confirmation mot de passe")}
                                        </label>
                                        <input
                                            id="cpassword"
                                            name="cpassword"
                                            type="password"
                                            className="form-control"
                                           
                                            style={{ height: "25px" }}
                                            value={inputEmail}
                                        />
                                    </div>
                                </div>
                            ) : null} */}
                        </AvForm>

                        <img
                            height="93px"
                            width="301px"
                            alt="img"
                            src="./images/clavier.PNG"
                        />
                    </div>
                )}
                <ActionsButtons />
                {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
            </div>
        </PreviewBackgroundWrapper>
    );
}
