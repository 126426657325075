import React, { useState } from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { getUniqueId } from "@helpers/general";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { setLimitToast, store } from "@components/VerticalLayout/store";
import {
    resetPrinterData,
    setPrinterData,
    store as printerDataStore,
} from "./store";
import {
    store as generalStore,
    setIsActionsClicked,
    setActions,
} from "../../store";
import { resetAllPeripherals } from "../../DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";
import { resetCashManagementData } from "../CashManagementPeripheralConfigure/store";
import { resetMoneticData } from "../MoneticPeripheralConfigure/store";

import { verifyIfPeripheralAssociate } from "../../../../helpers/peripheralsHelpers";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import classnames from "classnames";
import { ipMatchWord } from "@constants/index";

type FooterType = {
    setDataIsUpdated: Function;
    editedData: any;
    selectedRows: any;
    isAddPeripheralClicked: boolean;
    isEdited: boolean;
    setIsAddPeripheralClicked: Function;
    setEditedData: Function;
    setIsEdited: Function;
    setIsConfirmationEditModalOpened: Function;
    shopIds: any;
    isConsult: boolean;
    setIsConsult: Function;
};

export function CardFooter({
    setEditedData,
    setDataIsUpdated,
    editedData,
    selectedRows,
    isAddPeripheralClicked,
    isEdited,
    setIsAddPeripheralClicked,
    setIsEdited,
    setIsConfirmationEditModalOpened,
    shopIds,
    isConsult,
    setIsConsult,
}: FooterType) {
    const { t } = useTranslation();

    const { isActionsClicked } = useSnapshot(generalStore);
    const [canValidate, setCanValidate] = useState<boolean>(true);
    const { franchiseID, userID, operatorID, resourceIp } = useSnapshot(store);
    const [error, setError] = React.useState<boolean>(false);
    const { oneShop } = useSnapshot(userStore);
    const { shopID } = useSnapshot(userStore);
    const shopId = oneShop ? shopID : selectedRows?.shopId;
    const appId = "KIOSK";
    const uuidUser = localStorage.getItem("uuidUser");

    const {
        printerType,
        printerPath,
        designation,
        mark,
        note,
        speed,
        ip,
        port_series,
        fontSize,
        ticketWidth,
        ipPortNumber,
        isNameExist,
        isIpPrinter,
        isUsbPrinter,
        isModalOpened,
        codeIsExist,
        isCodeValid,
        printerCode,
        tickets,
        ipExist,
        isWaiting,
    } = useSnapshot(printerDataStore);
    const [salesModes, setSalesModes] = React.useState<any>([]);

    const fetchModeOfSale = React.useCallback(() => {
        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    return {
                        // eslint-disable-next-line array-callback-return
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            if (el === "KIOSK")
                                return {
                                    designation: saleMode["Mode of sale"],
                                    state: false,
                                };
                        }),
                    };
                });
                let rowDataSales: any[] = [];
                let salesSupportMode: any[] = [];
                if (
                    selectedRows !== undefined &&
                    selectedRows["customer_ticket"]
                        ?.customerTicketSalesModes !== undefined
                ) {
                    for (let element of selectedRows["customer_ticket"]
                        ?.customerTicketSalesModes) {
                        rowDataSales.push(element);
                    }
                }
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });

                let sales: any[] = [];
                let salesModes: any[] = [];
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        if (
                            selectedRows["customer_ticket"]
                                ?.customerTicketSalesModes === undefined ||
                            selectedRows["customer_ticket"]
                                ?.customerTicketSalesModes.length === 0
                        ) {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    selectedRows["customer_ticket"]
                                        ?.customerTicketSalesModes ===
                                        undefined ||
                                    selectedRows["customer_ticket"]
                                        ?.customerTicketSalesModes.length === 0
                                ) {
                                    elt !== undefined && sales.push(elt);
                                }
                            });
                        } else {
                            for (let element of selectedRows["customer_ticket"]
                                ?.customerTicketSalesModes) {
                                salesModes.push(element.designation);
                                sales.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    sales.push(elt);
                                }
                            });
                        }
                    });
                sales = [...new Set(sales)];
                for (let sale of rowDataSales) {
                    sales = sales.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                setSalesModes(sales);
            });
        // eslint-disable-next-line
    }, [franchiseID, shopId, userID, editedData]);

    React.useEffect(() => {
        fetchModeOfSale();
    }, [fetchModeOfSale]);

    async function handleEditButtonClickEventPrinter() {
        toast.dismiss();
        resetAllPeripherals();
        if (canValidate) {
            setPrinterData(true, "isWaiting");
            setCanValidate(false);
            let ifPeripheralAssociateObject: {
                isPeripheralAssociate: boolean;
                appId_children: any[];
            } = await verifyIfPeripheralAssociate(
                editedData.peripheralId,
                shopId,
                franchiseID,
                userID,
                appId
            );

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;
            let data = [
                { label: "Customer", value: "01" },
                { label: "Production", value: "02" },
                {
                    label: "Sticker",
                    value: "03",
                },
            ];

            let originTickets = data.filter(
                (el: any) => tickets.value === el.value
            );

            let dataPrint;
            if (
                selectedRows["tickets"].value === "01" &&
                tickets.value === "02"
            ) {
                dataPrint = {
                    Kitchen_ticket: {
                        note_kitchen_ticket: {
                            print_note: true,
                        },
                        ticketKitchenModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: true,
                            };
                        }),
                        customerAddressModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        customer_information_mode_sale: {
                            customerInformationModeSaleName: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleNumTel: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCity: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleAdress: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCode: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCompany: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                        },
                    },
                    customer_ticket: {
                        customerTicketSalesModes: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),

                        dateTimeDeliveryModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        print_date_time_delivery_mode_sale: {
                            provider_name: false,
                            without_price: false,
                            print_notes: false,
                            items_lines: false,
                            method_payment: false,
                            method_payment_TVA: false,
                        },
                    },
                };
            }
            if (
                (selectedRows["tickets"].value === "02" ||
                    selectedRows["tickets"].value === "03") &&
                tickets.value === "01"
            ) {
                dataPrint = {
                    Kitchen_ticket: {
                        note_kitchen_ticket: {
                            print_note: false,
                        },
                        ticketKitchenModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        customerAddressModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        customer_information_mode_sale: {
                            customerInformationModeSaleName: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleNumTel: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCity: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleAdress: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCode: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCompany: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                        },
                    },
                    customer_ticket: {
                        customerTicketSalesModes: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: true,
                            };
                        }),

                        dateTimeDeliveryModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        print_date_time_delivery_mode_sale: {
                            provider_name: false,
                            without_price: true,
                            print_notes: true,
                            items_lines: false,
                            method_payment: false,
                            method_payment_TVA: false,
                        },
                    },
                };
            }
            let savedData = {
                userId: userID,
                shopId: shopId,
                franchiseId: franchiseID,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: editedData.peripheralId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: [
                    {
                        id: editedData.peripheralId,
                        designation: designation.trim(),
                        mark: mark,
                        note: note,
                        shopId: shopId,
                        type: printerType,
                        speed: speed,
                        path: printerPath,
                        ip: printerType !== "ip" ? "" : ip,
                        port: ipPortNumber,
                        font_size: fontSize,
                        edit_date: new Date(),
                        add_date: "",
                        ticket_width: ticketWidth,
                        port_series: port_series,
                        isArchived: false,
                        code: printerCode,
                        tickets: originTickets[0],
                        Kitchen_ticket: dataPrint?.Kitchen_ticket,
                        customer_ticket: dataPrint?.customer_ticket,
                    },
                ],
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error!");
                            }
                            toast.success(
                                `${t("The device was successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (
                                ifPeripheralAssociateObject.isPeripheralAssociate
                            )
                                setTimeout(RedisPublishPeripheral, 3000);
                            setDataIsUpdated(true);
                            resetCashManagementData();
                            setIsAddPeripheralClicked(!isAddPeripheralClicked);
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                            setCanValidate(true);
                            setPrinterData(false, "isWaiting");
                        })
                );
            } catch (e: any) {
                setPrinterData(false, "isWaiting");
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function RedisPublishPeripheral() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: oneShop ? shopID : shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function handleValidateButtonClickEventPrinter() {
        let hasIpConflict: number = 0;
        toast.dismiss();
        resetAllPeripherals();
        if (canValidate) {
            setCanValidate(false);
            setPrinterData(true, "isWaiting");
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer`;
            let data = [
                { label: "Customer", value: "01" },
                { label: "Production", value: "02" },
                {
                    label: "Sticker",
                    value: "03",
                },
            ];

            let originTickets = data.filter(
                (el: any) => tickets.value === el.value
            );

            (oneShop ? [shopId] : shopIds).forEach(async (element: any) => {
                let savedData = {
                    userId: userID,
                    shopId: element,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: [
                        {
                            id: getUniqueId(10),
                            designation: designation.trim(),
                            mark: mark,
                            note: note,
                            shopId: shopId,
                            type: printerType,
                            speed: printerType !== "ip" ? speed : "",
                            path: printerPath,
                            ip: ip,
                            port: ipPortNumber,
                            font_size: fontSize,
                            edit_date: new Date(),
                            add_date: new Date(),
                            ticket_width: ticketWidth,
                            port_series: port_series,
                            isArchived: false,
                            code: printerCode,
                            tickets: originTickets[0],
                        },
                    ],
                };
                let filteredData = JSON.parse(JSON.stringify(resourceIp))
                    ?.filter(
                        (item: any) =>
                            item?.shopId === element &&
                            item?.type !== "printer" &&
                            item.ip.trim() !== ""
                    )
                    .map((y: any) => {
                        return y.ip;
                    });
                filteredData = [...new Set(filteredData)];
                if (filteredData.includes(ip)) {
                    hasIpConflict++;
                }

                if (!filteredData.includes(ip)) {
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                })
                        );
                    } catch (e: any) {
                        setPrinterData(false, "isWaiting");
                        setCanValidate(true);
                        setError(true);
                        toast.error(`${t("There's an error")}!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
            });
            if (hasIpConflict > 0) {
                setLimitToast(2);
                ErrorToast(
                    t(
                        "Please note, the device could not be added to one or more stores. Please check the IP address settings."
                    )
                );
            }
            if (error === false) {
                toast.success(`${t("The device is successfully added")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });

                setPrinterData(false, "isWaiting");
                setDataIsUpdated(true);
                setCanValidate(true);
                setEditedData([]);
                resetAllPeripherals();
                resetPrinterData();
                resetCashManagementData();
                resetMoneticData();
                setEditedData([]);
                setIsEdited(false);
                activateCheckbox();
            }
        }
    }

    function handleValidate() {
        if (tickets === null) {
            setPrinterData(true, "inputTicket");
        }
        if (designation === "") {
            setPrinterData(true, "inputDesignation");
        }
        if (ip === "") {
            setPrinterData(true, "inputIp");
        }
        if (printerPath === "") {
            setPrinterData(true, "inputPrinterPath");
        }
        if (editedData.length === 0) {
            if (
                (isIpPrinter &&
                    !codeIsExist &&
                    isCodeValid &&
                    printerCode !== "" &&
                    tickets !== null &&
                    ip.length !== 0 &&
                    designation.length !== 0 &&
                    ip.match(ipMatchWord) &&
                    !isNameExist &&
                    !ipExist &&
                    designation.trim() !== "") ||
                (!isIpPrinter &&
                    !codeIsExist &&
                    isCodeValid &&
                    printerCode !== "" &&
                    tickets !== null &&
                    !isUsbPrinter &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "") ||
                (isUsbPrinter &&
                    !codeIsExist &&
                    isCodeValid &&
                    printerCode !== "" &&
                    tickets !== null &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "")
            ) {
                if (shopId === undefined) {
                    ErrorToast(`${t("Attention ! you must select a store.")!}`);
                } else {
                    handleValidateButtonClickEventPrinter();
                }
            }
        } else {
            if (isEdited === false) {
                setPrinterData(!isModalOpened, "isModalOpened");
                setIsConfirmationEditModalOpened(true);
            } else if (
                (isIpPrinter &&
                    !codeIsExist &&
                    isCodeValid &&
                    printerCode !== "" &&
                    tickets !== null &&
                    ip.length !== 0 &&
                    designation.length !== 0 &&
                    ip.match(ipMatchWord) &&
                    !isNameExist &&
                    designation.trim() !== "") ||
                (!isIpPrinter &&
                    !codeIsExist &&
                    isCodeValid &&
                    printerCode !== "" &&
                    tickets !== null &&
                    !isUsbPrinter &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "") ||
                (isUsbPrinter &&
                    !codeIsExist &&
                    isCodeValid &&
                    printerCode !== "" &&
                    tickets !== null &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "")
            )
                handleEditButtonClickEventPrinter();
        }
    }
    function activateCheckbox() {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    }

    return (
        <React.Fragment>
            <CustomSecondaryColorButton
                outline
                rounded
                variant="light"
                className="mr-2 afp_btn_FRcancel cmn_btn_cancel"
                onClick={() => {
                    if (isEdited === true) {
                        setIsActionsClicked(!isActionsClicked);
                        setActions(() => {
                            activateCheckbox();
                            setIsAddPeripheralClicked(!isAddPeripheralClicked);
                            resetPrinterData();
                            resetCashManagementData();
                            resetAllPeripherals();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                        });
                    } else {
                        activateCheckbox();
                        setIsAddPeripheralClicked(!isAddPeripheralClicked);
                        resetPrinterData();
                        resetCashManagementData();
                        resetAllPeripherals();
                        resetMoneticData();
                        setEditedData([]);
                        setIsEdited(false);
                    }
                    setIsConsult(false);
                }}
            >
                <span> {t("Cancel")}</span>
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                variant="primary"
                className={classnames("afp_btn_FRvalidate cmn_btn_validate", {
                    "not-allowed-icon__clz": isWaiting || isConsult,
                })}
                onClick={handleValidate}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </React.Fragment>
    );
}
