import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { diff } from "deep-diff";
import moment from "moment";

import {
    computedStore,
    generalConfigStore,
    setIsModalOpened,
} from "../../store";
import { store } from "../../components/VerticalLayout/store";
import {
    setIsLoading,
    setIsModalConfirmationConfigurationKiosk,
    setKioskProject,
} from "../../store/project/index";
import { setIsValidateClicked } from "@pages/GeneralConfig/store";
import { setKey } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    rowData: any;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    setSelectedRows: Function;
};

export function ModalWrapperKioskSetting({
    children,
    rowData,
    setSelectedRows,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop, shopID } = useSnapshot(
        store
    );
    const {
        isModalOpened,
        isWaysTabActive,
        project: { template },
    } = useSnapshot(generalConfigStore);
    const uuidUser = localStorage.getItem("uuidUser");

    const { items } = useSnapshot(computedStore);
    const navigate = useNavigate();

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

    const toggle = () => {
        setIsModalOpened(!isModalOpened);
        setSelectedRows([]);
    };

    async function handleUpdateKioskButtonOnClickEvent(data1: any) {
        setIsLoading(true);
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/kiosk/template`;
        const data: Record<string, any> = {};
        data[rowData.id_project] = {
            ...data1,
            modified_at: `${moment().format("MM/DD/YY [at] HH:mm a")}`,
        };
        let savedData = {
            shopId: rowData.shopId,
            userId: userID,
            franchiseId: franchiseID,
            appId: "KIOSK",
            appId_children: rowData.appId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: data,
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        toast.success(`${t("Template saved successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                        setIsModalOpened(!isModalOpened);
                        setIsLoading(false);
                        setKey(Math.floor(Math.random() * 10000));
                        if (rowData.Status === "Publié") {
                            setTimeout(RedisPublish, 3000);
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function RedisPublish() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/redis`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: rowData.shopId,
                        nameKiosk: rowData[t("Designation")],
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    async function handleUpdateShopButtonOnClickEvent(data1: any) {
        let apiUrl: any;
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        setIsLoading(true);
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: oneShop ? rowData.projectId : rowData.id_project,
                    shopId: oneShop ? shopID : rowData.id_boutique,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        template: data1.template,
                        files: data1.files,
                        hoursOfUse: JSON.parse(
                            localStorage?.getItem("hoursOfUse")!
                        ),
                        modified_at: `${moment().format(
                            "MM/DD/YY [at] HH:mm a"
                        )}`,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    setKioskProject(data1);
                    toast.success(`${t("Template saved successfully")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });
                    if (
                        rowData?.subRows.length > 0 &&
                        rowData?.subRows.some(
                            (el: any) => el.template !== ""
                        ) &&
                        (diff(rowData.template, data1.template) !== undefined ||
                            diff(rowData.files, data1.files) !== undefined ||
                            diff(rowData.hoursOfUse, data1.hoursOfUse) !==
                                undefined)
                    ) {
                        setSelectedRows(rowData);
                        setIsModalConfirmationConfigurationKiosk(true);
                    } else {
                        setKey(Math.floor(Math.random() * 10000));
                        if (rowData.Status === "Publié") {
                            setTimeout(RedisPublish, 3000);
                        }
                    }
                    setIsLoading(false);
                    setIsModalOpened(!isModalOpened);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    React.useEffect(() => {
        if (isDataChanged === true) {
            let newProject = JSON.parse(localStorage.getItem("project")!);
            newProject.hoursOfUse = JSON.parse(
                localStorage.getItem("hoursOfUse")!
            );
            if (
                JSON.parse(localStorage.getItem("hoursOfUse")!) !== "" &&
                newProject.hoursOfUse !== undefined
            ) {
                newProject.hoursOfUse.map((element: any, key: number) => {
                    if ((element.state === true && key === 0) || key !== 0) {
                        element.hours.forEach((elt: any) => {
                            if (elt.startTime === "" && elt.endTime !== "") {
                                elt.inputStart = true;
                            } else if (
                                elt.startTime !== "" &&
                                elt.endTime === ""
                            ) {
                                elt.inputEnd = true;
                            } else {
                                elt.inputStart = false;
                                elt.inputEnd = false;
                            }
                        });
                    } else {
                        newProject.hoursOfUse[0].hours.forEach((el: any) => {
                            el.inputEnd = false;
                            el.inputStart = false;
                        });
                    }
                    return true;
                });
            }
            setIsValidateClicked(true);
            localStorage.setItem(
                "hoursOfUse",
                JSON.stringify(newProject.hoursOfUse)
            );
            if (
                (newProject.hoursOfUse !== "" &&
                    newProject.hoursOfUse.every((element: any) =>
                        element.hours.every(
                            (elt: any) =>
                                elt.inputEnd === false &&
                                elt.inputStart === false
                        )
                    )) ||
                newProject.hoursOfUse === ""
            ) {
                (!oneShop && rowData.id_boutique === undefined) ||
                (oneShop && rowData.key_project === undefined)
                    ? handleUpdateKioskButtonOnClickEvent(newProject)
                    : handleUpdateShopButtonOnClickEvent(newProject);
            }
            setIsDataChanged(false);
        }
        // eslint-disable-next-line
    }, [isDataChanged, JSON.parse(localStorage.getItem("project")!)]);

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            toggle={toggle}
            isOpen={isModalOpened}
            className="modal-wrapper__clz"
        >
            <ModalHeader toggle={toggle} className="text-uppercase cursor__clz">
                {rowData.id_boutique === undefined
                    ? t("Kiosk settings")
                    : t("Store Template Setup")}
            </ModalHeader>
            <ModalBody className="p-0">{children}</ModalBody>
            <ModalFooter>
                {isWaysTabActive ? (
                    <CustomMainColorButton
                        outline={true}
                        rounded={true}
                        className="m-2"
                        variant="primary"
                        disabled={Object.values(items.ways).length === 0}
                        onClick={() => navigate("/fullscreen-preview")}
                    >
                        {t("See")}
                    </CustomMainColorButton>
                ) : null}
                <CustomMainColorButton
                    disabled={
                        template.id === undefined || isDisabled ? true : false
                    }
                    className="w-20"
                    rounded
                    variant="primary"
                    onClick={() => {
                        setIsDisabled(true);
                        setIsDataChanged(true);
                    }}
                >
                    {t("Save")}
                </CustomMainColorButton>
            </ModalFooter>
        </Modal>
    );
}
