import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon, StyledBreadcrumb } from "@aureskonnect/react-ui";
import { UpdateResourceIp } from "@helpers/general";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { ToastContainer } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import {
    setIsMultiDeletePeripheralModalOpened,
    setIsUpdateOperation,
    store as moneticsStore,
    setActions,
    setIsActionsClicked,
    setValidationAction,
    setIsEdited,
    setModify,
    setIsLoading,
    setKey,
} from "./store";
import { setAllMonetics } from "@pages/Applications/DeviceAssociateModal/store";

import { getPathAuthorization } from "@components/VerticalLayout";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import MoneticsApplicationForm from "@pages/Applications/DeviceAssociateModal/MoneticsApplicationForm";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import { ConfirmationMultiDeleteMessage } from "@components/Common/ConfirmationMultiDeleteMessage";
import BadgeNumber from "@components/Common/BadgeNumber";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import { PeripheralsConfigurations } from "./PeripheralsConfigurations/index";
import { CustomIcons } from "./CustomIcons";
import { MoneticsHistory } from "./MoneticsHistory";
import DropdownAction from "./DropdownAction";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Monetics() {
    const { t } = useTranslation();
    const location = useLocation();
    const { oneShop, operatorID, userID, franchiseID, shopID } = useSnapshot(
        store
    );
    const {
        isMultiDeletePeripheralModalOpened,
        isActionsClicked,
        actions,
        validationAction,
        isEdited,
        modify,
        key,
    } = useSnapshot(moneticsStore);

    setModify(getPathAuthorization(location.pathname).Modifie);
    const didMountRef = React.useRef(false);
    const [isAddMoneticsClicked, setIsAddMoneticsClicked] = React.useState<
        boolean
    >(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddNewMoneticsButtonClicked,
        setIsAddNewMoneticsButtonClicked,
    ] = useState(false);
    const [rowData, setRowData] = useState<any>({});
    const [data, setData] = useState<any>([]);
    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);
    const [
        isMoneticsPathsModalOpened,
        setIsMoneticsPathsModalOpened,
    ] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [ipExist, setIpExist] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [moneticId, setMoneticId] = React.useState<string>("");
    const [monetics, setMonetics] = useState<any>([]);
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [localShop, setLocalShop] = React.useState<any>([]);
    const [selectedIds, setSelectedIds] = React.useState<any>([]);
    const [ListNumberOrigin, setListNumberOrigin] = React.useState<any>({
        nbrNotArchived: 0,
        nbrArchived: 0,
    });
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [selectMark, setSelectMark] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const uuidUser = localStorage.getItem("uuidUser");
    const [InputIp, setInputIp] = React.useState<boolean>(false);
    const [url, setUrl] = React.useState<string>(
        oneShop
            ? `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
    );
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [objectApiUrlDissociate, setObjectApiUrlDissociate] = React.useState<
        any
    >({});
    const [isHistoryModalOpened, setIsHistoryModalOpened] = useState(false);

    const disableCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.add("not-allowed-icon-check__clz");
        }
    };
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 5 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setRowData={setRowData}
                    setMonetics={setMonetics}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsMoneticsPathsModalOpened={
                        setIsMoneticsPathsModalOpened
                    }
                    isMoneticsPathsModalOpened={isMoneticsPathsModalOpened}
                    setMoneticId={setMoneticId}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                    objectApiUrlDissociate={objectApiUrlDissociate}
                    setObjectApiUrlDissociate={setObjectApiUrlDissociate}
                    setInputIp={setInputIp}
                    setIsConfirmationEditRunning={setIsConfirmationEditRunning}
                    typeDisplay={typeDisplay}
                    setSelectedRows={setSelectedRows}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setIsConsult={setIsConsult}
                    setIsAddMoneticsClicked={setIsAddMoneticsClicked}
                    isAddMoneticsClicked={isAddMoneticsClicked}
                    isConsult={isConsult}
                />
            ),
        },
        {
            indexOFColumn: oneShop ? 0 : 2,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {!oneShop ? (
                            e.selectedRow[t("Shop")] !== undefined ||
                            (e.selectedRow[t("Shop")] !== "" &&
                                e.selectedRow.subRows) !== undefined ? (
                                <BadgeNumber
                                    value={e.selectedRow.subRows?.length}
                                />
                            ) : (
                                e.selectedRow[t("Designation")]
                            )
                        ) : (
                            e.selectedRow[t("Designation")]
                        )}
                    </div>
                );
            },
        },
    ];

    React.useEffect(() => {
        if (
            selectedRows[0] !== undefined &&
            selectedRows[0].subRows !== undefined
        )
            selectedRows[0].subRows.forEach((e: any) => {
                setAllMonetics(e);
            });
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );
        setIsAddMoneticsClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneShop, userID, shopID, franchiseID, i18nextLng]);

    React.useEffect(() => {
        setListNumberOrigin(data?.othersData?.ListNumberOrigin);
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeDisplay]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let localShopIds: string[] = [];
        let ids: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t(`Shop`)] !== "" && el[t(`Shop`)] !== undefined) {
                localShop.push(el[t(`Shop`)]);
                localShopIds.push(el.shopId);
            } else {
                ids.push({
                    Id: el.Id,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                });
            }
        });
        setLocalShop(localShopIds);
        setSelectedIds(ids);
        setIsShopSelected(localShop.length >= 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    React.useEffect(() => {
        if (monetics.Id && !oneShop) {
            if (
                data.data
                    .filter((bt: any) => {
                        return bt.shopId === monetics.shopId;
                    })[0]
                    ?.subRows.filter((imp: any) => {
                        return imp.Id === monetics.Id;
                    })[0] !== undefined
            ) {
                setMonetics(
                    data.data
                        .filter((bt: any) => {
                            return bt.shopId === monetics.shopId;
                        })[0]
                        ?.subRows.filter((imp: any) => {
                            return imp.Id === monetics.Id;
                        })[0]
                );
            }
        }
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        if (selectedRows[0] !== undefined)
            selectedRows[0].subRows.forEach((e: any) => {
                setAllMonetics(e);
            });

        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line
    }, [oneShop, userID, shopID, franchiseID, typeDisplay]);

    React.useEffect(() => {
        setListNumberOrigin(data?.othersData?.ListNumberOrigin);
    }, [data]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let localShopIds: string[] = [];
        let ids: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t(`Shop`)] !== "" && el[t(`Shop`)] !== undefined) {
                localShop.push(el[t(`Shop`)]);
                localShopIds.push(el.shopId);
            } else {
                ids.push({
                    Id: el.Id,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                });
            }
        });
        setLocalShop(localShopIds);
        setSelectedIds(ids);
        setIsShopSelected(localShop.length >= 1);
    }, [franchiseID, selectedRows, typeDisplay, userID, t]);

    React.useEffect(() => {
        if (filterActive) {
            setIsAddMoneticsClicked(false);
            setMonetics([]);
        }
    }, [filterActive]);

    React.useEffect(() => {
        if (isAddMoneticsClicked === true) {
            setLocalFilterActive(false);
        }
    }, [isAddMoneticsClicked]);

    React.useEffect(() => {
        if (dataIsUpdated && didMountRef.current) {
            UpdateResourceIp(userID, franchiseID);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIsUpdated, franchiseID, userID]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <ToastContainer limit={2} />
            <PageTitle title={t("Peripherals")} />
            <div className="page-content__clz">
                <div className="mnt_txt_monetics">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("Peripherals").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: t(
                                    typeDisplay === "notArchived"
                                        ? "Monetics"
                                        : "Archived monetics"
                                ).toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>
                <MessageInfo
                    message={t(
                        "In this section, you can configure TPEs of different types such as Nepting, Valina, Adyen, and many others."
                    )}
                />
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddMoneticsClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        key={key}
                        url={url}
                        actionColumn={() => <></>}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        canExpand={oneShop ? false : true}
                        canSelect
                        customSelect
                        canMovedCheckboxLeftOnExpand
                        setSelectedRows={setSelectedRows}
                        setData={setData}
                        customJsxSideFilterButton={
                            <div className="d-flex justify-content-start align-items-center ml-2">
                                {modify === true &&
                                isAddMoneticsClicked !== true ? (
                                    <div className=" cmn_btn_openForm">
                                        <span
                                            onClick={() => {
                                                disableCheckbox();
                                                setMonetics([]);
                                                setIsAddMoneticsClicked(
                                                    !isAddMoneticsClicked
                                                );
                                                setShopId(
                                                    oneShop === false
                                                        ? selectedRows[0].shopId
                                                        : shopID
                                                );
                                                setLocalFilterActive(false);
                                            }}
                                            className={`${classnames({
                                                "not-allowed-icon__clz":
                                                    (!isShopSelected &&
                                                        !oneShop) ||
                                                    typeDisplay === "archived",
                                            })}`}
                                        >
                                            <CustomMainColorButtonWithIcon
                                                icon="PlusIcon"
                                                iconPosition="left"
                                                className="w-100"
                                                rounded
                                                variant="primary"
                                            >
                                                {t("Add device")}
                                            </CustomMainColorButtonWithIcon>
                                        </span>
                                    </div>
                                ) : modify === true ? (
                                    <div className="d-flex justify-content-start">
                                        <CustomMainColorIconButton
                                            icon="PlusIcon"
                                            className=" mnt_btn_reset"
                                            rounded
                                            disabled={
                                                monetics.length === 0
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                if (isEdited === true)
                                                    setIsInInitializationModalOpened(
                                                        true
                                                    );
                                                else {
                                                    setInputDesignation(false);
                                                    setSelectMark(false);
                                                }
                                            }}
                                        >
                                            <PlusIcon
                                                height={20}
                                                width={20}
                                                fill="white"
                                            />
                                        </CustomMainColorIconButton>
                                    </div>
                                ) : null}
                                <DropdownAction
                                    typeDisplay={typeDisplay}
                                    setTypeDisplay={setTypeDisplay}
                                    selectedIds={selectedIds}
                                    ListNumberOrigin={ListNumberOrigin}
                                    setDataIsUpdated={setDataIsUpdated}
                                    selectedRows={selectedRows}
                                    setIsHistoryModalOpened={
                                        setIsHistoryModalOpened
                                    }
                                    setIsAddMoneticsClicked={
                                        setIsAddMoneticsClicked
                                    }
                                />
                            </div>
                        }
                        canResize
                        showGlobalFilter
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="monetics"
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                    />
                    {isAddMoneticsClicked ? (
                        <MoneticsApplicationForm
                            setIsAddMoneticsClicked={setIsAddMoneticsClicked}
                            isAddMoneticsClicked={isAddMoneticsClicked}
                            setDataIsUpdated={setDataIsUpdated}
                            editedData={monetics}
                            isAddNewMoneticsButtonClicked={
                                isAddNewMoneticsButtonClicked
                            }
                            idShop={shopId}
                            setEditedData={setMonetics}
                            isEdited={isEdited}
                            setIsEdited={setIsEdited}
                            setIsAddNewMoneticsButtonClicked={
                                setIsAddNewMoneticsButtonClicked
                            }
                            selectMark={selectMark}
                            setSelectMark={setSelectMark}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            InputIp={InputIp}
                            setInputIp={setInputIp}
                            shopIds={oneShop ? [shopID] : localShop}
                            tableData={data}
                            setIsUpdateOperation={setIsUpdateOperation}
                            setIpExist={setIpExist}
                            ipExist={ipExist}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                </div>
                <PeripheralsConfigurations
                    data={rowData}
                    setIsMoneticsPathsModalOpened={
                        setIsMoneticsPathsModalOpened
                    }
                    isMoneticsPathsModalOpened={isMoneticsPathsModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    dataM={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                    }}
                />
                {isModalDeleteAssociatePeripheralOpened === false ? (
                    <ConfirmationDeleteMessage
                        isModalOpened={isModalOpened}
                        setIsModalOpened={setIsModalOpened}
                        setDataIsUpdated={setDataIsUpdated}
                        text={t(
                            typeDisplay === "notArchived"
                                ? "Please confirm archivation of this device"
                                : "Please confirm unarchivation of this device"
                        )}
                        message={t(
                            typeDisplay === "notArchived"
                                ? "The device was successfully archived"
                                : "The device was successfully unarchived"
                        )}
                        apiUrl={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/monetics?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&operatorId=${operatorID}&id=${moneticId}&isArchived=${
                            typeDisplay === "notArchived" ? "true" : "false"
                        }&uuidUser=${uuidUser}`}
                        data={{ shopId: shopId }}
                        setKey={setKey}
                        setIsLoading={setIsLoading}
                    />
                ) : (
                    <AlertDeletePeripheralMessage
                        text={t(
                            "This device is associated with one or more applications! Unable to perform delete action"
                        )}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                    />
                )}
                {isInInitializationModalOpened === true ? (
                    <ConfirmationInitializationMessage
                        isModalOpened={isInInitializationModalOpened}
                        setIsModalOpened={setIsInInitializationModalOpened}
                        setIsClicked={setIsAddNewMoneticsButtonClicked}
                        isClicked={isAddNewMoneticsButtonClicked}
                        setIsEdited={setIsEdited}
                    />
                ) : null}
                {isConfirmationEditRunning === true ? (
                    <ConfirmationMessageEditPeripheral
                        isModalOpened={isConfirmationEditRunning}
                        setIsModalOpened={setIsConfirmationEditRunning}
                        setCancel={setIsAddMoneticsClicked}
                        archive={typeDisplay === "archived"}
                    />
                ) : null}
                {isMultiDeletePeripheralModalOpened === true && (
                    <ConfirmationMultiDeleteMessage
                        isModalOpened={isMultiDeletePeripheralModalOpened}
                        setIsModalOpened={setIsMultiDeletePeripheralModalOpened}
                    />
                )}
                {isHistoryModalOpened ? (
                    <MoneticsHistory
                        selectedRows={selectedRows}
                        isHistoryModalOpened={isHistoryModalOpened}
                        setIsHistoryModalOpened={setIsHistoryModalOpened}
                        setGlobalDataUpdated={setDataIsUpdated}
                    />
                ) : null}
                {isActionsClicked === true ? (
                    <ConfirmationUpdatingDataMessageModal
                        isModalOpened={isActionsClicked}
                        setIsEdited={setIsEdited}
                        setIsModalOpened={setIsActionsClicked}
                        validationAction={validationAction}
                        actions={actions}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
}
