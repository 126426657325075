import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    designStore,
    setHeaderBenefitsClientInformationMessage,
    setHeaderCustomerBalanceMessage,
} from "@store";

import { CustomInput as Input } from "@components/Common/CustomInput";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();
    const { project } = useSnapshot(generalConfigStore);

    const { selectedLanguage } = useSnapshot(designStore);

    const informationMessageContent = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .informationMessage as CustomerBalanceType;
    const messageCustomerBalanceContent = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .customerBalance as CustomerBalanceType;

    function handleInputInformationMessageContentInputOnChangeEvent(
        content: string
    ) {
        const inputInformationMessageContent: RefactoredLanguageType = {
            ...(informationMessageContent.languages as RefactoredLanguageType),
            [selectedLanguage]: content,
        };
        setHeaderBenefitsClientInformationMessage(
            inputInformationMessageContent
        );
    }

    function handleInputCustomerBalanceContentInputOnChangeEvent(
        content: string
    ) {
        const inputCustomerBalanceContent: RefactoredLanguageType = {
            ...(messageCustomerBalanceContent.languages as RefactoredLanguageType),
            [selectedLanguage]: content,
        };
        setHeaderCustomerBalanceMessage(inputCustomerBalanceContent);
    }

    function handleResetTranslationOnClickEvent() {
        const resetInformationMessageContent: RefactoredLanguageType = {
            ...(informationMessageContent.languages as RefactoredLanguageType),
            [selectedLanguage]: t(`${informationMessageContent.resetContent}`, {
                lng: selectedLanguage,
            }),
        };
        const resetCustomerBalanceContent: RefactoredLanguageType = {
            ...(messageCustomerBalanceContent.languages as RefactoredLanguageType),
            [selectedLanguage]: t(
                `${messageCustomerBalanceContent.resetContent}`,
                {
                    lng: selectedLanguage,
                }
            ),
        };
        setHeaderBenefitsClientInformationMessage(
            resetInformationMessageContent
        );

        setHeaderCustomerBalanceMessage(resetCustomerBalanceContent);
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "30%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2">{t("Custom")}</div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "30%" }}
                            >
                                {t(
                                    `${
                                        informationMessageContent.resetContent
                                            .charAt(0)
                                            .toUpperCase() +
                                        informationMessageContent.resetContent
                                            .slice(1)
                                            .toLowerCase()
                                    }`,
                                    {
                                        lng: selectedLanguage,
                                    }
                                )}
                            </td>
                            <td>
                                <Input
                                    value={
                                        (informationMessageContent.languages as RefactoredLanguageType)[
                                            selectedLanguage
                                        ]
                                    }
                                    onChange={(event: any) => {
                                        handleInputInformationMessageContentInputOnChangeEvent(
                                            event.target.value
                                        );
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "30%" }}
                            >
                                {t(
                                    `${
                                        messageCustomerBalanceContent.resetContent
                                            .charAt(0)
                                            .toUpperCase() +
                                        messageCustomerBalanceContent.resetContent
                                            .slice(1)
                                            .toLowerCase()
                                    }`,
                                    {
                                        lng: selectedLanguage,
                                    }
                                )}
                            </td>
                            <td>
                                <Input
                                    value={
                                        (messageCustomerBalanceContent.languages as RefactoredLanguageType)[
                                            selectedLanguage
                                        ]
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        handleInputCustomerBalanceContentInputOnChangeEvent(
                                            event.target.value
                                        );
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}
