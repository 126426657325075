import React from "react";
import { useSnapshot } from "valtio";
import { StyledLabel } from "@aureskonnect/react-ui";

import { designStore } from "@store";
import { truncateString } from "@helpers/general";

export function HeaderWrapper(): JSX.Element {
    const { selectedLanguage, subStepInformationMessageItems } = useSnapshot(
        designStore
    );

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center cursor__clz">
                <StyledLabel>
                    {truncateString(
                        subStepInformationMessageItems[selectedLanguage]
                            .content,
                        40
                    )}
                </StyledLabel>
            </div>
        </React.Fragment>
    );
}
