import React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useSnapshot } from "valtio";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
    generalConfigStore,
    setItems,
    setIsStateChanged,
    setIsCustomerAccountActive,
    setConfigOptions,
    setIsPaperlessTicketActive,
    setPageActionsButtonsItems,
    setIsConfirmationEmailActive,
    setIsEmailActive,
    setIsUnauthenticatedEmailActive,
} from "@store";

import { DraggableElement } from "./DraggableElement";

export function removeItemFromList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[],
    index: number
): [
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    ),
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[]
] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[] = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
}

export function addItemInList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[],
    index: number,
    element:
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
): (
    | PageType
    | ConsommationPageType
    | StandbyScreenPageType
    | PaymentPageType
    | OrderTakingPageType
    | PrinterOptionsPageType
)[] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[] = Array.from(list);
    result.splice(index, 0, element);
    return result;
}

export function DragList(): JSX.Element {
    const { t } = useTranslation();

    const {
        isCustomerAccountActive,
        isStateChanged,
        isInformationModesEmpty,
        project,
    } = useSnapshot(generalConfigStore);

    function handleOnDragEndEvent(result: DropResult) {
        if (!result.destination) {
            return;
        }

        const localPagesItems: ItemsPagesType = { ...project.template.pages };

        const sourceList: (
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType
        )[] = Object.values(localPagesItems[result.source.droppableId]);

        const informationModesConsommationIndex = Object.keys(
            localPagesItems.ways
        ).includes("informationModesConsommation")
            ? Object.keys(localPagesItems.ways).indexOf(
                  "informationModesConsommation"
              )
            : -1;

        const informationModesIndex = Object.keys(
            localPagesItems.ways
        ).includes("informationModes")
            ? Object.keys(localPagesItems.ways).indexOf("informationModes")
            : -1;

        const authenticationModesIndex = Object.keys(
            localPagesItems.ways
        ).includes("authenticationModes")
            ? Object.keys(localPagesItems.ways).indexOf("authenticationModes")
            : -1;

        const connectionIndex = Object.keys(localPagesItems.ways).includes(
            "connection"
        )
            ? Object.keys(localPagesItems.ways).indexOf("connection")
            : -1;

        const loyaltyConsommationIndex = Object.keys(
            localPagesItems.ways
        ).includes("loyaltyConsommation")
            ? Object.keys(localPagesItems.ways).indexOf("loyaltyConsommation")
            : -1;
        const meansOfPaymentIndex = Object.keys(localPagesItems.ways).includes(
            "meansOfPayment"
        )
            ? Object.keys(localPagesItems.ways).indexOf("meansOfPayment")
            : -1;

        const selectedSaleMethodDeliveryExist = (project.template.content
            .salesMethods as ProjectContentItemType).items.filter(
            (sale: any) => sale.name === "Delivery"
        )[0];

        if (
            result.destination.droppableId === "ways" &&
            result.source.droppableId === "ways"
        ) {
            if (
                result.destination.index === 0 &&
                result.source.index !==
                    Object.keys(localPagesItems.ways).indexOf("standbyScreen")
            ) {
                toast.warning(
                    t(
                        "Attention, You can only put the 'Standby screen' step as the first step."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index === 0 &&
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("standbyScreen")
            ) {
                toast.warning(
                    t(
                        "Attention, You can only put the 'Standby screen' step as the first step."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.destination.index ===
                    Object.keys(localPagesItems.ways).length - 1 &&
                result.source.index !==
                    Object.keys(localPagesItems.ways).indexOf("finalMessage")
            ) {
                toast.warning(
                    t("You can only put this step in the last position."),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("printerOptions")
            ) {
                toast.warning(
                    t(
                        "You can only put this step before payment step position."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf(
                        "printerOptions"
                    ) &&
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("payment")
            ) {
                toast.warning(
                    t(
                        "You can only put this step after printer options step position."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).length - 1 &&
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("finalMessage")
            ) {
                toast.warning(
                    t(t("You can only put this step in the last position.")),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf("orderTaking")
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'payment' step before 'order taking' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("salesMethods") &&
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf("orderTaking")
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'sales methods' step after 'order taking' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModes"
                    ) &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf("salesMethods") &&
                informationModesIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'information modes' step before 'sales modes' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("salesMethods") &&
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModes"
                    ) &&
                informationModesIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'sales modes' step after 'information modes' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("orderTaking")
            ) {
                if (
                    result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf("salesMethods")
                ) {
                    toast.warning(
                        t(
                            "Attention, you cannot put the 'order taking' step before 'sale mode' step"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }

                if (
                    result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf("payment")
                ) {
                    toast.warning(
                        t(
                            "Attention, you cannot put the 'order taking' step after 'payment' step"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "meansOfPayment"
                    ) &&
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                meansOfPaymentIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'means of payment' step after the 'payment' step."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf(
                        "meansOfPayment"
                    ) &&
                meansOfPaymentIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'payment' step after the 'means of payment' step."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "authenticationModes"
                    ) &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf("connection") &&
                authenticationModesIndex !== -1 &&
                connectionIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'authentication mode' step before the 'connection mode' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("connection") &&
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf(
                        "authenticationModes"
                    ) &&
                authenticationModesIndex !== -1 &&
                connectionIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'connection mode' step after the 'authentication mode' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "loyaltyConsommation"
                    ) &&
                result.destination.index <= authenticationModesIndex &&
                authenticationModesIndex !== -1 &&
                loyaltyConsommationIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'loyalty consommation' step before the 'authentication mode' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "loyaltyConsommation"
                    ) &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf("orderTaking") &&
                loyaltyConsommationIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'loyalty consommation' step before the 'order taking' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("orderTaking") &&
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf(
                        "loyaltyConsommation"
                    ) &&
                loyaltyConsommationIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'order taking' step after the 'loyalty consommation' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "loyaltyConsommation"
                    ) &&
                result.destination.index >
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                loyaltyConsommationIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'loyalty consommation' step after the 'payment' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.destination.index ===
                    Object.keys(localPagesItems.ways).indexOf("finalMessage") -
                        1 &&
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf("printerOptions")
            ) {
                let data: ItemsPagesType = { ...localPagesItems };
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(data["ways"]),
                    result.source.index
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                data["ways"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(data["ways"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    result.destination.index,
                    removedItem1
                ) as PageType[];

                data["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
                setItems(data);

                const filteredItems = (data.ways
                    .printerOptions as PageType).actionsButtons.items.filter(
                    (item: ActionsButtonsDesignItemType) => {
                        const BACK_BUTTON_ROLE = 3;

                        const ROLES = [BACK_BUTTON_ROLE];

                        return ROLES.includes(item.role);
                    }
                );
                const findIndex = filteredItems.find(
                    (element) => element.name === "Back"
                );

                if (findIndex) {
                    const actionButtonItem = filteredItems.indexOf(findIndex);

                    filteredItems[actionButtonItem] = {
                        ...findIndex,
                        active: false,
                    };

                    return setPageActionsButtonsItems(
                        filteredItems,
                        "printerOptions"
                    );
                }
            }

            // if (
            //     result.source.index ===
            //         Object.keys(localPagesItems.ways).indexOf(
            //             "informationModes"
            //         ) &&
            //     result.destination.index >=
            //         Object.keys(localPagesItems.ways).indexOf("payment") &&
            //     Object.keys(localPagesItems.ways).indexOf(
            //         "informationModes"
            //     ) !== -1
            // ) {
            //     toast.warning(
            //         t(
            //             "Attention, you cannot put the 'information modes' step after the 'payment' step"
            //         ),
            //         {
            //             position: toast.POSITION.TOP_CENTER,
            //             autoClose: 2000,
            //             theme: "colored",
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //         }
            //     );
            //     return;
            // }

            // if (
            //     result.source.index ===
            //         Object.keys(localPagesItems.ways).indexOf(
            //             "informationModesConsommation"
            //         ) &&
            //     result.destination.index >=
            //         Object.keys(localPagesItems.ways).indexOf("payment") &&
            //     Object.keys(localPagesItems.ways).indexOf(
            //         "informationModesConsommation"
            //     ) !== -1
            // ) {
            //     toast.warning(
            //         t(
            //             "Attention, you cannot put the 'information modes consommation' step after the 'payment' step"
            //         ),
            //         {
            //             position: toast.POSITION.TOP_CENTER,
            //             autoClose: 2000,
            //             theme: "colored",
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //         }
            //     );
            //     return;
            // }

            // if (
            //     result.source.index ===
            //         Object.keys(localPagesItems.ways).indexOf(
            //             "informationModes"
            //         ) &&
            //     result.destination.index >=
            //         Object.keys(localPagesItems.ways).indexOf(
            //             "loyaltyConsommation"
            //         ) &&
            //     Object.keys(localPagesItems.ways).indexOf(
            //         "informationModes"
            //     ) !== -1
            // ) {
            //     toast.warning(
            //         t(
            //             "Attention, you cannot put the 'information modes' step after the 'loyalty consommation' step"
            //         ),
            //         {
            //             position: toast.POSITION.TOP_CENTER,
            //             autoClose: 2000,
            //             theme: "colored",
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //         }
            //     );
            //     return;
            // }

            // if (
            //     result.source.index ===
            //         Object.keys(localPagesItems.ways).indexOf(
            //             "informationModesConsommation"
            //         ) &&
            //     result.destination.index >=
            //         Object.keys(localPagesItems.ways).indexOf(
            //             "loyaltyConsommation"
            //         ) &&
            //     Object.keys(localPagesItems.ways).indexOf(
            //         "informationModesConsommation"
            //     ) !== -1
            // ) {
            //     toast.warning(
            //         t(
            //             "Attention, you cannot put the 'information modes consommation' step after the 'loyalty consommation' step"
            //         ),
            //         {
            //             position: toast.POSITION.TOP_CENTER,
            //             autoClose: 2000,
            //             theme: "colored",
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //         }
            //     );
            //     return;
            // }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "authenticationModes"
                    ) &&
                result.destination.index >= loyaltyConsommationIndex &&
                authenticationModesIndex !== -1 &&
                loyaltyConsommationIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'authentication mode' step after the 'loyalty consommation' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModesConsommation"
                    ) &&
                isInformationModesEmpty === false &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModes"
                    ) &&
                informationModesConsommationIndex !== -1 &&
                informationModesIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'information mode consommation' step before the 'information mode' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModes"
                    ) &&
                isInformationModesEmpty === false &&
                Object.keys(localPagesItems.ways).indexOf(
                    "informationModesConsommation"
                ) === result.destination.index &&
                informationModesConsommationIndex !== -1 &&
                informationModesIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'information mode' step after the 'information mode consommation' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModes"
                    ) &&
                result.destination.index >=
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModesConsommation"
                    ) &&
                informationModesIndex !== -1 &&
                informationModesConsommationIndex !== -1
            ) {
                let data: ItemsPagesType = { ...localPagesItems };

                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(data["ways"]),
                    result.source.index
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                data["ways"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(data["ways"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    result.destination.index,
                    removedItem1
                ) as PageType[];

                data["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );

                let newLocalPageItem: ItemsPagesType = {
                    ...data,
                };
                const informationModesConsommationIndex = Object.keys(
                    newLocalPageItem.ways
                ).indexOf("informationModesConsommation");

                const informationModeNewIndex = Object.keys(
                    newLocalPageItem.ways
                ).indexOf("informationModes");

                const [removedElement, newSourceList] = removeItemFromList(
                    Object.values(newLocalPageItem["ways"]),
                    informationModesConsommationIndex
                );

                const removedItem = { ...removedElement };

                removedItem.prefix = "ways";

                newLocalPageItem["ways"] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(newLocalPageItem["ways"]);

                let newDestinationList = addItemInList(
                    destinationList,
                    informationModeNewIndex,
                    removedItem
                ) as PageType[];

                newLocalPageItem["ways"] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );

                return setItems(newLocalPageItem);
            }
        }

        if (
            result.source.droppableId === "elements" &&
            result.destination.droppableId === "ways"
        ) {
            if (
                isPaymentWithLoyaltyBalance === false &&
                result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "loyaltyConsommation"
                    )
            ) {
                toast.warning(
                    t(
                        "Please note, you cannot add this step to the route. The Payment with loyalty balance option is disabled."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (result.destination.index === 0) {
                toast.warning(
                    t(
                        "Attention, you can put only the 'Standby screen' step as the first step."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.destination.index >
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "printerOptions"
                    )
            ) {
                toast.warning(
                    t(
                        "You can only put this step before payment step position."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.destination.index >
                Object.keys(localPagesItems.ways).indexOf("finalMessage")
            ) {
                toast.warning(
                    t(
                        "You can only put 'final message' step in the last position."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (isInformationModesEmpty === true) {
                const localPagesItems: ItemsPagesType = {
                    ...project.template.pages,
                };
                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "informationModes"
                        ) ||
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "informationModesConsommation"
                        )
                ) {
                    toast.warning(
                        t(
                            "Attention, this is an impossible action, please check your general settings"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }
            }

            if (
                (result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "authenticationModes"
                    ) ||
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "connection"
                        ) ||
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "loyaltyConsommation"
                        )) &&
                isStateChanged === false
            ) {
                setIsCustomerAccountActive(true);
                const initOptions = (project.template.content
                    .options as OptionsType).items;
                let newState = [...initOptions];
                newState[0] = {
                    ...newState[0],
                    active: true,
                };
                setConfigOptions(newState);
                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "connection"
                        ) &&
                    authenticationModesIndex === -1 &&
                    connectionIndex === -1 &&
                    loyaltyConsommationIndex === -1
                ) {
                    let data: ItemsPagesType = { ...localPagesItems };

                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(data["elements"]),
                        result.source.index
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "ways";

                    data["elements"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(data["ways"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        result.destination.index,
                        removedItem1
                    ) as PageType[];

                    data["ways"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );

                    let newLocalPageItem: ItemsPagesType = {
                        ...data,
                    };
                    const authenticationModesIndex = Object.keys(
                        newLocalPageItem.elements
                    ).indexOf("authenticationModes");

                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(newLocalPageItem["elements"]),
                        authenticationModesIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "ways";

                    newLocalPageItem["elements"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        newLocalPageItem["ways"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        result.destination.index + 1,
                        removedItem
                    ) as PageType[];

                    newLocalPageItem["ways"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );
                    let newData: ItemsPagesType = { ...newLocalPageItem };
                    let loyaltyConsommationIndex = Object.keys(
                        newData.elements
                    ).indexOf("loyaltyConsommation");
                    if (isPaymentWithLoyaltyBalance === true) {
                        const [
                            removedElement2,
                            newSourceList2,
                        ] = removeItemFromList(
                            Object.values(newData["elements"]),
                            loyaltyConsommationIndex
                        );

                        const removedItem2 = { ...removedElement2 };

                        removedItem2.prefix = "ways";

                        newData["elements"] = Object.assign(
                            {},
                            ...newSourceList2.map((element) => ({
                                [element.name]: { ...element },
                            }))
                        );

                        const destinationList2 = Object.values(newData["ways"]);
                        const orderTakingIndex = Object.keys(
                            newData.ways
                        ).indexOf("orderTaking");
                        const authenticationModesNewIndex = Object.keys(
                            newData.ways
                        ).indexOf("authenticationModes");

                        let newDestinationList2 = addItemInList(
                            destinationList2,
                            authenticationModesNewIndex > orderTakingIndex
                                ? authenticationModesNewIndex + 1
                                : orderTakingIndex + 1,
                            removedItem2
                        ) as PageType[];

                        newData["ways"] = Object.assign(
                            {},
                            ...newDestinationList2.map(
                                (element, index: number) => ({
                                    [element.name]: {
                                        ...element,
                                        index: index,
                                    },
                                })
                            )
                        );
                    }
                    return setItems(newData);
                }
                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "authenticationModes"
                        ) &&
                    authenticationModesIndex === -1 &&
                    loyaltyConsommationIndex === -1
                ) {
                    let data: ItemsPagesType = { ...localPagesItems };

                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(data["elements"]),
                        result.source.index
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "ways";

                    data["elements"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(data["ways"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        result.destination.index,
                        removedItem1
                    ) as PageType[];

                    data["ways"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );

                    let newData: ItemsPagesType = { ...data };
                    let loyaltyConsommationIndex = Object.keys(
                        newData.elements
                    ).indexOf("loyaltyConsommation");
                    if (isPaymentWithLoyaltyBalance === true) {
                        const [
                            removedElement2,
                            newSourceList2,
                        ] = removeItemFromList(
                            Object.values(newData["elements"]),
                            loyaltyConsommationIndex
                        );

                        const removedItem2 = { ...removedElement2 };

                        removedItem2.prefix = "ways";

                        newData["elements"] = Object.assign(
                            {},
                            ...newSourceList2.map((element) => ({
                                [element.name]: { ...element },
                            }))
                        );

                        const destinationList2 = Object.values(newData["ways"]);
                        const orderTakingIndex = Object.keys(
                            newData.ways
                        ).indexOf("orderTaking");
                        const authenticationModesIndex = Object.keys(
                            newData.ways
                        ).indexOf("authenticationModes");

                        let newDestinationList2 = addItemInList(
                            destinationList2,
                            result.destination.index > orderTakingIndex
                                ? result.destination.index + 1
                                : authenticationModesIndex > orderTakingIndex
                                ? authenticationModesIndex + 1
                                : orderTakingIndex + 1,
                            removedItem2
                        ) as PageType[];

                        newData["ways"] = Object.assign(
                            {},
                            ...newDestinationList2.map(
                                (element, index: number) => ({
                                    [element.name]: {
                                        ...element,
                                        index: index,
                                    },
                                })
                            )
                        );
                    }
                    return setItems(newData);
                }
                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "connection"
                        ) &&
                    Object.keys(localPagesItems.ways).indexOf(
                        "authenticationModes"
                    ) > -1 &&
                    result.destination.index >
                        Object.keys(localPagesItems.ways).indexOf(
                            "authenticationModes"
                        )
                ) {
                    toast.warning(
                        t(
                            "Attention, you cannot put the 'connection mode' step after the 'authentication mode' step"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }
            }

            if (isInformationModesEmpty === false) {
                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "informationModesConsommation"
                        ) &&
                    informationModesIndex === -1
                ) {
                    toast.warning(
                        t(
                            "Attention, you cannot put the 'information mode consommation' step before the 'information mode' step"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }

                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "informationModesConsommation"
                        ) &&
                    informationModesIndex !== -1 &&
                    result.destination.index <= informationModesIndex
                ) {
                    toast.warning(
                        t(
                            "Attention, you cannot put the information mode consumption step before the information mode step"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }

                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "informationModes"
                        ) &&
                    result.destination.index <=
                        Object.keys(localPagesItems.ways).indexOf(
                            "salesMethods"
                        )
                ) {
                    toast.warning(
                        t(
                            "Attention, you cannot put the 'information modes' step before 'sales modes' step"
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    return;
                }

                if (
                    result.source.index ===
                        Object.keys(localPagesItems.elements).indexOf(
                            "informationModes"
                        ) &&
                    informationModesIndex === -1 &&
                    informationModesConsommationIndex === -1
                ) {
                    let data: ItemsPagesType = { ...localPagesItems };

                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(data["elements"]),
                        result.source.index
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "ways";

                    data["elements"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(data["ways"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        result.destination.index,
                        removedItem1
                    ) as PageType[];

                    data["ways"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );

                    let newLocalPageItem: ItemsPagesType = {
                        ...data,
                    };
                    const informationModesConsommationIndex = Object.keys(
                        newLocalPageItem.elements
                    ).indexOf("informationModesConsommation");

                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(newLocalPageItem["elements"]),
                        informationModesConsommationIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "ways";

                    newLocalPageItem["elements"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        newLocalPageItem["ways"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        result.destination.index + 1,
                        removedItem
                    ) as PageType[];

                    newLocalPageItem["ways"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );

                    return setItems(newLocalPageItem);
                }
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "meansOfPayment"
                    ) &&
                result.destination.index >
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                meansOfPaymentIndex === -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'means of payment' step after the 'payment' step."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "loyaltyConsommation"
                    ) &&
                result.destination.index <=
                    Object.keys(localPagesItems.ways).indexOf("orderTaking") &&
                loyaltyConsommationIndex === -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'loyalty consommation' step before the 'order taking' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "loyaltyConsommation"
                    ) &&
                result.destination.index >
                    Object.keys(localPagesItems.ways).indexOf("payment") &&
                loyaltyConsommationIndex === -1
            ) {
                toast.warning(
                    t(
                        "Attention, you cannot put the 'loyalty consommation' step after the 'payment' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                Object.keys(localPagesItems.ways).indexOf("printerOptions") ===
                    -1 &&
                result.source.index ===
                    Object.keys(localPagesItems.elements).indexOf(
                        "printerOptions"
                    )
            ) {
                let data: ItemsPagesType = { ...localPagesItems };
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(data["elements"]),
                    result.source.index
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                data["elements"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(data["ways"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    result.destination.index,
                    removedItem1
                ) as PageType[];

                data["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                            isPaperlessTicketActive: true,
                        },
                    }))
                );
                setIsPaperlessTicketActive(true);

                return setItems(data);
                // const filteredItems = (data.ways
                //     .printerOptions as PageType).actionsButtons.items.filter(
                //     (item: ActionsButtonsDesignItemType) => {
                //         const BACK_BUTTON_ROLE = 3;

                //         const ROLES = [BACK_BUTTON_ROLE];

                //         return ROLES.includes(item.role);
                //     }
                // );
                // const findIndex = filteredItems.find(
                //     (element) => element.name === "Back"
                // );

                // if (findIndex) {
                //     const actionButtonItem = filteredItems.indexOf(findIndex);

                //     filteredItems[actionButtonItem] = {
                //         ...findIndex,
                //         active: false,
                //     };
                //     setIsPaperlessTicketActive(true);

                //     // setPageActionsButtonsItems(filteredItems, "printerOptions");
                //     return setItems(data);
                // }
            }
        }

        if (
            result.source.droppableId === "ways" &&
            result.destination.droppableId === "elements"
        ) {
            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("orderTaking")
            ) {
                toast.warning(t("You cannot remove order taking step"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                return;
            }
            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("payment")
            ) {
                toast.warning(
                    t(
                        "Attention, it is not recommended to remove payment step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (result.source.index === 0) {
                toast.warning(
                    t(
                        "Attention, it is not recommended to remove standby screen step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("finalMessage")
            ) {
                toast.warning(t("You cannot remove final message step"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                return;
            }

            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("salesMethods")
            ) {
                toast.warning(
                    t(
                        "Attention, it is not recommended to remove sale mode step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "authenticationModes"
                    ) &&
                selectedSaleMethodDeliveryExist !== undefined &&
                selectedSaleMethodDeliveryExist.active === true
            ) {
                toast.warning(
                    t("You cannot remove authentication modes step"),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "authenticationModes"
                    ) &&
                (selectedSaleMethodDeliveryExist === undefined ||
                    selectedSaleMethodDeliveryExist.active === false)
            ) {
                let newState = [
                    ...(project.template.content.options as OptionsType).items,
                ];
                const indexItem = newState.findIndex(
                    (item) => item.id === "00fa26a0-0bc0-4986-9769-4ffe08d4498d"
                );
                newState[indexItem] = {
                    ...newState[indexItem],
                    active: false,
                };
                let newLocalPageItem: ItemsPagesType = {
                    ...localPagesItems,
                };
                console.log("hi there !");
                if (authenticationModesIndex !== -1) {
                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(newLocalPageItem["ways"]),
                        authenticationModesIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "elements";

                    newLocalPageItem["ways"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        newLocalPageItem["elements"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        authenticationModesIndex,
                        removedItem
                    ) as PageType[];

                    newLocalPageItem["elements"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );
                }

                let data: ItemsPagesType = { ...newLocalPageItem };

                if (connectionIndex !== -1) {
                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(data["ways"]),
                        connectionIndex
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "elements";

                    data["ways"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(data["elements"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        connectionIndex,
                        removedItem1
                    ) as PageType[];

                    data["elements"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );
                }
                setConfigOptions(newState);
                setIsCustomerAccountActive(false);
                setIsStateChanged(true);
                setItems(data);
                return;
            }

            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("informationModes")
            ) {
                const [removedElement, newSourceList] = removeItemFromList(
                    sourceList,
                    result.source.index
                );
                const removedItem = { ...removedElement };

                removedItem.prefix = result.destination.droppableId;

                localPagesItems[result.source.droppableId] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(
                    localPagesItems[result.destination.droppableId]
                );

                const newDestinationList = addItemInList(
                    destinationList,
                    result.destination.index,
                    removedItem
                ) as PageType[];

                localPagesItems[result.destination.droppableId] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index: number) => ({
                        [element.name]: { ...element, index: index },
                    }))
                );

                const newLocalPagesItems1: ItemsPagesType = {
                    ...localPagesItems,
                };
                const informationModesConsommationIndex = Object.keys(
                    newLocalPagesItems1.ways
                ).includes("informationModesConsommation")
                    ? Object.keys(newLocalPagesItems1.ways).indexOf(
                          "informationModesConsommation"
                      )
                    : -1;

                if (informationModesConsommationIndex !== -1) {
                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(newLocalPagesItems1["ways"]),
                        informationModesConsommationIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "elements";

                    newLocalPagesItems1["ways"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        newLocalPagesItems1["elements"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        informationModesConsommationIndex,
                        removedItem
                    ) as PageType[];

                    newLocalPagesItems1["elements"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );
                }
                return setItems(newLocalPagesItems1);
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "informationModesConsommation"
                    ) &&
                informationModesIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, it is not recommended to remove 'information modes consommation' step before 'information modes' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
            if (
                result.source.index ===
                    Object.keys(localPagesItems.ways).indexOf(
                        "loyaltyConsommation"
                    ) &&
                authenticationModesIndex !== -1
            ) {
                toast.warning(
                    t(
                        "Attention, it is not recommended to remove 'loyalty consommation' step before 'authentication modes' step"
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }

            if (
                result.source.index ===
                Object.keys(localPagesItems.ways).indexOf("printerOptions")
            ) {
                setIsEmailActive(false);
                setIsConfirmationEmailActive(false);
                setIsUnauthenticatedEmailActive(false);
                setIsPaperlessTicketActive(false);

                const [removedElement, newSourceList] = removeItemFromList(
                    sourceList,
                    result.source.index
                );
                const removedItem = { ...removedElement };

                removedItem.prefix = result.destination.droppableId;

                localPagesItems[result.source.droppableId] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(
                    localPagesItems[result.destination.droppableId]
                );

                const newDestinationList = addItemInList(
                    destinationList,
                    result.destination.index,
                    removedItem
                ) as PrinterOptionsPageType[];

                localPagesItems[result.destination.droppableId] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                            isPaperlessTicketActive: false,
                            printerOptionsConfig: {
                                ...(element.printerOptionsConfig as PrinterOptionsConfigType),
                                isEmailActive: false,
                                isConfirmationEmailActive: false,
                                isUnauthenticatedEmailActive: false,
                            },
                        },
                    }))
                );

                return setItems(localPagesItems);
            }
        }

        const [removedElement, newSourceList] = removeItemFromList(
            sourceList,
            result.source.index
        );
        const removedItem = { ...removedElement };

        removedItem.prefix = result.destination.droppableId;

        localPagesItems[result.source.droppableId] = Object.assign(
            {},
            ...newSourceList.map((element) => ({
                [element.name]: { ...element },
            }))
        );

        const destinationList = Object.values(
            localPagesItems[result.destination.droppableId]
        );

        const newDestinationList = addItemInList(
            destinationList,
            result.destination.index,
            removedItem
        ) as PageType[];

        localPagesItems[result.destination.droppableId] = Object.assign(
            {},
            ...newDestinationList.map((element, index: number) => ({
                [element.name]: { ...element, index: index },
            }))
        );

        setItems(localPagesItems);
    }
    const isPaymentWithLoyaltyBalance = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).paymentWithLoyaltyBalance;
    React.useEffect(() => {
        if (isInformationModesEmpty === true) {
            let newLocalPageItem: ItemsPagesType = {
                ...project.template.pages,
            };

            const informationModesIndex = Object.keys(
                newLocalPageItem.ways
            ).includes("informationModes")
                ? Object.keys(newLocalPageItem.ways).indexOf("informationModes")
                : -1;

            if (informationModesIndex !== -1) {
                const [removedElement, newSourceList] = removeItemFromList(
                    Object.values(newLocalPageItem["ways"]),
                    informationModesIndex
                );

                const removedItem = { ...removedElement };

                removedItem.prefix = "elements";

                newLocalPageItem["ways"] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(
                    newLocalPageItem["elements"]
                );

                let newDestinationList = addItemInList(
                    destinationList,
                    informationModesIndex,
                    removedItem
                ) as PageType[];

                newLocalPageItem["elements"] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            let data: ItemsPagesType = { ...newLocalPageItem };

            const informationModesConsommationIndex = Object.keys(
                data.ways
            ).includes("informationModesConsommation")
                ? Object.keys(data.ways).indexOf("informationModesConsommation")
                : -1;

            if (informationModesConsommationIndex !== -1) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(data["ways"]),
                    informationModesConsommationIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "elements";

                data["ways"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(data["elements"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    informationModesConsommationIndex,
                    removedItem1
                ) as PageType[];

                data["elements"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            setItems(data);
        } // eslint-disable-next-line
    }, [isInformationModesEmpty]);

    React.useEffect(() => {
        if (isCustomerAccountActive === false && isStateChanged === true) {
            const localPagesItems: ItemsPagesType = {
                ...project.template.pages,
            };

            const authenticationModesIndex = Object.keys(
                localPagesItems.ways
            ).includes("authenticationModes")
                ? Object.keys(localPagesItems.ways).indexOf(
                      "authenticationModes"
                  )
                : -1;

            let connectionIndex = Object.keys(localPagesItems.ways).includes(
                "connection"
            )
                ? Object.keys(localPagesItems.ways).indexOf("connection")
                : -1;

            let loyaltyConsommationIndex = Object.keys(
                localPagesItems.ways
            ).includes("loyaltyConsommation")
                ? Object.keys(localPagesItems.ways).indexOf(
                      "loyaltyConsommation"
                  )
                : -1;

            if (
                localPagesItems.ways.authenticationModes === undefined &&
                localPagesItems.ways.connection === undefined &&
                localPagesItems.ways.loyaltyConsommation === undefined
            ) {
                setIsStateChanged(false);

                return;
            } else {
                let newLocalPageItem: ItemsPagesType = {
                    ...localPagesItems,
                };

                if (authenticationModesIndex !== -1) {
                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(newLocalPageItem["ways"]),
                        authenticationModesIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "elements";

                    newLocalPageItem["ways"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        newLocalPageItem["elements"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        authenticationModesIndex,
                        removedItem
                    ) as PageType[];

                    newLocalPageItem["elements"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );
                }

                let data: ItemsPagesType = { ...newLocalPageItem };

                if (connectionIndex !== -1) {
                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(data["ways"]),
                        connectionIndex
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "elements";

                    data["ways"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(data["elements"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        connectionIndex,
                        removedItem1
                    ) as PageType[];

                    data["elements"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );
                }

                let newData: ItemsPagesType = { ...data };
                loyaltyConsommationIndex = Object.keys(newData.ways).includes(
                    "loyaltyConsommation"
                )
                    ? Object.keys(newData.ways).indexOf("loyaltyConsommation")
                    : -1;
                if (
                    loyaltyConsommationIndex !== -1 &&
                    isPaymentWithLoyaltyBalance === true
                ) {
                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(newData["ways"]),
                        loyaltyConsommationIndex
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "elements";

                    newData["ways"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(newData["elements"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        loyaltyConsommationIndex,
                        removedItem1
                    ) as PageType[];

                    newData["elements"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );
                }
                setIsStateChanged(false);
                setItems(newData);
                return;
            }
        }

        if (
            isCustomerAccountActive === true &&
            isStateChanged === true &&
            (project.template.pages.elements
                .authenticationModes as PageType) !== undefined &&
            (project.template.pages.elements.connection as PageType) !==
                undefined &&
            (project.template.pages.elements
                .loyaltyConsommation as PageType) !== undefined
        ) {
            const localPagesItems: ItemsPagesType = {
                ...project.template.pages,
            };

            let data: ItemsPagesType = { ...localPagesItems };

            let connectionIndex = Object.keys(data.elements).includes(
                "connection"
            )
                ? Object.keys(data.elements).indexOf("connection")
                : -1;
            if (connectionIndex !== -1) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(data["elements"]),
                    connectionIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                data["elements"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(data["ways"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    1,
                    removedItem1
                ) as PageType[];

                data["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            let newLocalPageItem: ItemsPagesType = {
                ...data,
            };
            const authenticationModesIndex = Object.keys(
                newLocalPageItem.elements
            ).includes("authenticationModes")
                ? Object.keys(newLocalPageItem.elements).indexOf(
                      "authenticationModes"
                  )
                : -1;
            if (authenticationModesIndex !== -1) {
                const [removedElement, newSourceList] = removeItemFromList(
                    Object.values(newLocalPageItem["elements"]),
                    authenticationModesIndex
                );

                const removedItem = { ...removedElement };

                removedItem.prefix = "ways";

                newLocalPageItem["elements"] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(newLocalPageItem["ways"]);

                let newDestinationList = addItemInList(
                    destinationList,
                    2,
                    removedItem
                ) as PageType[];

                newLocalPageItem["ways"] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            let newData: ItemsPagesType = { ...newLocalPageItem };
            let loyaltyConsommationIndex = Object.keys(
                newData.elements
            ).includes("loyaltyConsommation")
                ? Object.keys(newData.elements).indexOf("loyaltyConsommation")
                : -1;
            if (
                loyaltyConsommationIndex !== -1 &&
                isPaymentWithLoyaltyBalance === true
            ) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(newData["elements"]),
                    loyaltyConsommationIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                newData["elements"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(newData["ways"]);
                const orderTakingIndex = Object.keys(newData.ways).indexOf(
                    "orderTaking"
                );
                let newDestinationList1 = addItemInList(
                    destinationList1,
                    orderTakingIndex + 1,
                    removedItem1
                ) as PageType[];

                newData["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            setIsStateChanged(false);
            return setItems(newData);
        }
    }, [
        isCustomerAccountActive,
        project.template.pages,
        isStateChanged,
        isPaymentWithLoyaltyBalance,
    ]);

    React.useEffect(() => {
        if (isPaymentWithLoyaltyBalance === false && isStateChanged === true) {
            let newData: ItemsPagesType = { ...project.template.pages };
            const loyaltyConsommationIndex = Object.keys(newData.ways).includes(
                "loyaltyConsommation"
            )
                ? Object.keys(newData.ways).indexOf("loyaltyConsommation")
                : -1;
            if (loyaltyConsommationIndex !== -1) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(newData["ways"]),
                    loyaltyConsommationIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "elements";

                newData["ways"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(newData["elements"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    loyaltyConsommationIndex,
                    removedItem1
                ) as PageType[];

                newData["elements"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }
            setItems(newData);
            return setIsStateChanged(false);
        }
        if (isPaymentWithLoyaltyBalance === true && isStateChanged === true) {
            let newData: ItemsPagesType = { ...project.template.pages };
            let loyaltyConsommationIndex = Object.keys(
                newData.elements
            ).includes("loyaltyConsommation")
                ? Object.keys(newData.elements).indexOf("loyaltyConsommation")
                : -1;
            if (loyaltyConsommationIndex !== -1) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(newData["elements"]),
                    loyaltyConsommationIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                newData["elements"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(newData["ways"]);
                const orderTakingIndex = Object.keys(newData.ways).indexOf(
                    "orderTaking"
                );
                let newDestinationList1 = addItemInList(
                    destinationList1,
                    orderTakingIndex + 1,
                    removedItem1
                ) as PageType[];

                newData["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }
            setItems(newData);
            return setIsStateChanged(false);
        }
    }, [isStateChanged, isPaymentWithLoyaltyBalance, project.template.pages]);
    return (
        <React.Fragment>
            <DragDropContext onDragEnd={handleOnDragEndEvent}>
                {["elements", "ways"].map((item) => (
                    <DraggableElement
                        elements={Object.values(project.template.pages[item])}
                        key={item}
                        prefix={item}
                    />
                ))}
            </DragDropContext>
            <ToastContainer limit={1} />
        </React.Fragment>
    );
}
