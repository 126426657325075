import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import {
    EyeCrossedIcon,
    EyeIcon,
    RefreshIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { Uploader } from "@components/Uploader";
import {
    designStore,
    generalConfigStore,
    setInformationPrinterOptionsWithEmailItems,
    setInformationPrinterOptionsWithOutEmailItems,
    setItemsPrinterOptions,
    setProjectFiles,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";
import { CustomInput as Input } from "@components/Common/CustomInput";
import {
    WITHOUT_EMAIL_PRINTER_OPTIONS_TAB,
    WITH_EMAIL_PRINTER_OPTIONS_TAB,
} from "@constants/index";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const {
        project,
        imageKey,
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const { activeTabPrinterOptions, selectedLanguage } = useSnapshot(
        designStore
    );

    const informationPrinterOptionsWithOutEmailItems = (project.template.pages
        .ways["printerOptions"] as PrinterOptionsPageType).header
        .informationMessage.withoutemail;

    const informationPrinterOptionsWithEmailItems = (project.template.pages
        .ways["printerOptions"] as PrinterOptionsPageType).header
        .informationMessage.withemail;

    const itemsPrinterOptions = (project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).items;

    function handleResetTranslationOnClickEvent() {
        if (activeTabPrinterOptions === WITH_EMAIL_PRINTER_OPTIONS_TAB) {
            const localInformationMessageItems: PrinterOptionsInformationMessageContentType = {
                ...informationPrinterOptionsWithEmailItems,
                languages: {
                    ...informationPrinterOptionsWithEmailItems.languages,
                    [selectedLanguage]: {
                        defaultContent:
                            informationPrinterOptionsWithEmailItems.languages[
                                selectedLanguage
                            ].defaultContent,
                        content:
                            informationPrinterOptionsWithEmailItems.languages[
                                selectedLanguage
                            ].defaultContent,
                    },
                },
            };

            setInformationPrinterOptionsWithEmailItems(
                localInformationMessageItems
            );

            const localItems: any = [...Object.values(itemsPrinterOptions)];
            const elements: any = localItems.filter(
                (element: any) => element.withmail === true
            );

            if (elements.length > 0) {
                elements.forEach((el: any) => {
                    let itemIndex = localItems.indexOf(el);
                    let localLanguagesContent = el.languages;

                    localItems[itemIndex] = {
                        ...el,
                        languages: {
                            ...localLanguagesContent,
                            [selectedLanguage]: {
                                ...localLanguagesContent[selectedLanguage],
                                name:
                                    localLanguagesContent[selectedLanguage]
                                        .defaultName,
                            },
                        },
                    };
                });
                setItemsPrinterOptions(localItems);
            }
        } else {
            const localItems: any = [...Object.values(itemsPrinterOptions)];
            const elements: any = localItems.filter(
                (element: any) => element.withmail === false
            );
            const localInformationMessageItems: PrinterOptionsInformationMessageContentType = {
                ...informationPrinterOptionsWithOutEmailItems,
                languages: {
                    ...informationPrinterOptionsWithOutEmailItems.languages,
                    [selectedLanguage]: {
                        defaultContent:
                            informationPrinterOptionsWithOutEmailItems
                                .languages[selectedLanguage].defaultContent,
                        content:
                            informationPrinterOptionsWithOutEmailItems
                                .languages[selectedLanguage].defaultContent,
                    },
                },
            };
            if (elements.length > 0) {
                elements.forEach((el: any) => {
                    let itemIndex = localItems.indexOf(el);
                    let localLanguagesContent = el.languages;

                    localItems[itemIndex] = {
                        ...el,
                        languages: {
                            ...localLanguagesContent,
                            [selectedLanguage]: {
                                ...localLanguagesContent[selectedLanguage],
                                name: localLanguagesContent[selectedLanguage]
                                    .defaultName as string,
                            },
                        },
                    };
                });
                setItemsPrinterOptions(localItems);
            }

            setInformationPrinterOptionsWithOutEmailItems(
                localInformationMessageItems
            );
        }
    }

    function handleResetImagesOnClickEvent() {
        const activeItemsIds = Object.values(itemsPrinterOptions)
            .filter(
                (x: any) => x.withmail === !Boolean(activeTabPrinterOptions)
            )
            .map((item: any) => item.id);

        const imagesArray = [...images];
        activeItemsIds.forEach((id) => {
            const imageIndex = imagesArray.findIndex(
                (element) => element.id === id
            );
            const image = imagesArray[imageIndex];

            if (image !== undefined) {
                const localContent = image.content as ImageItemLanguagesType;
                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localContent,
                        [selectedLanguage as string]: {
                            ...localContent[selectedLanguage as string],
                            path:
                                localContent[selectedLanguage as string]
                                    .defaultImage,
                        },
                    } as ImageItemLanguagesType,
                };
            }
        });

        setProjectFiles(imagesArray);
    }

    function handleResetLanguagesTranslationsOnClickEvent() {
        //!  intialize  name of items to default content

        const initialActiveMainContentItems: any = [
            ...Object.values(itemsPrinterOptions),
        ].map((item: any) => {
            const languages = Object.entries(item.languages).reduce(
                (prev: any, [key, value]: any) => {
                    if (item.withmail === !Boolean(activeTabPrinterOptions)) {
                        return {
                            ...prev,
                            [key]: {
                                ...value,
                                name: value.defaultContent as string,
                                isTextVisible: true,
                            },
                        };
                    }
                    return {
                        ...prev,
                        [key]: value,
                    };
                },
                {}
            );

            return {
                ...item,
                languages,
            };
        });
        setItemsPrinterOptions(initialActiveMainContentItems);

        //!  modifying files project to default
        const activeItemsIds = Object.values(itemsPrinterOptions)
            .filter(
                (x: any) => x.withmail === !Boolean(activeTabPrinterOptions)
            )
            .map((item: any) => item.id);

        const imagesArray = [...images];
        activeItemsIds.forEach((id) => {
            const imageIndex = imagesArray.findIndex(
                (element) => element.id === id
            );
            const image = imagesArray[imageIndex];

            if (image !== undefined) {
                const localContent = image.content as ImageItemLanguagesType;
                const res = Object.keys(localContent).reduce(
                    (prev: any, curr: any) => {
                        const initialdata = {
                            ...localContent[curr],
                            path: localContent[curr].defaultImage as string,
                        };
                        return {
                            ...prev,
                            [curr]: initialdata,
                        };
                    },
                    {}
                );

                imagesArray[imageIndex] = {
                    ...image,
                    content: res,
                };
            }
        });

        setProjectFiles(imagesArray);

        if (activeTabPrinterOptions === WITH_EMAIL_PRINTER_OPTIONS_TAB) {
            const localInformationMessageItems = Object.fromEntries(
                Object.entries(
                    informationPrinterOptionsWithEmailItems.languages
                ).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        content: value.defaultContent as string,
                    },
                ])
            );

            setInformationPrinterOptionsWithEmailItems({
                ...informationPrinterOptionsWithEmailItems,
                languages: localInformationMessageItems,
            });
        } else {
            const localInformationMessageItems = Object.fromEntries(
                Object.entries(
                    informationPrinterOptionsWithOutEmailItems.languages
                ).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        content: value.defaultContent as string,
                    },
                ])
            );

            setInformationPrinterOptionsWithOutEmailItems({
                ...informationPrinterOptionsWithOutEmailItems,
                languages: localInformationMessageItems,
            });
        }
    }

    function handleInformationMessageItemInputOnChangeEvent(content: string) {
        if (activeTabPrinterOptions === WITH_EMAIL_PRINTER_OPTIONS_TAB) {
            const localItems: PrinterOptionsInformationMessageContentType = {
                ...informationPrinterOptionsWithEmailItems,
                languages: {
                    ...informationPrinterOptionsWithEmailItems.languages,
                    [selectedLanguage]: {
                        defaultContent:
                            informationPrinterOptionsWithEmailItems.languages[
                                selectedLanguage
                            ].defaultContent,
                        content: content,
                    },
                },
            };

            setInformationPrinterOptionsWithEmailItems(localItems);
        } else {
            const localItems: PrinterOptionsInformationMessageContentType = {
                ...informationPrinterOptionsWithOutEmailItems,
                languages: {
                    ...informationPrinterOptionsWithEmailItems.languages,
                    [selectedLanguage]: {
                        defaultContent:
                            informationPrinterOptionsWithOutEmailItems
                                .languages[selectedLanguage].defaultContent,
                        content: content,
                    },
                },
            };

            setInformationPrinterOptionsWithOutEmailItems(localItems);
        }
    }

    function handleCheckIconOnClickEvent(id: string) {
        const newItems = Object.values(itemsPrinterOptions);

        let localItems: any = [...newItems];

        const element: any = localItems.find((element: any) => {
            return element.id === id;
        });

        if (element !== undefined) {
            const itemIndex = localItems.indexOf(element);
            const localLanguagesContent = (element as any).languages;

            localItems[itemIndex] = {
                ...element,
                languages: {
                    ...localLanguagesContent,
                    [selectedLanguage]: {
                        ...localLanguagesContent[selectedLanguage],
                        isTextVisible: !localLanguagesContent[selectedLanguage]
                            .isTextVisible,
                    },
                },
            };
            setItemsPrinterOptions(localItems);
        }
    }

    function handleActiveItemInputOnChangeEvent(value: string, id: string) {
        const localItems: any = [...Object.values(itemsPrinterOptions)];

        const itemIndex: number = localItems.findIndex(
            (element: any) => element.id === id
        );

        if (localItems[itemIndex] !== undefined) {
            const localLanguagesContent = localItems[itemIndex].languages;

            localItems[itemIndex] = {
                ...localItems[itemIndex],
                languages: {
                    ...localLanguagesContent,
                    [selectedLanguage]: {
                        ...localLanguagesContent[selectedLanguage],
                        name: value,
                    },
                },
            };

            setItemsPrinterOptions(localItems);
        }
    }

    return (
        <div>
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    icon="RefreshIcon"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2">
                <SwiperLanguagesWrapper />
                <div className="mt-3 mx-4 ">
                    <AvForm>
                        <Table bordered className="languages-table__clz">
                            <thead>
                                <tr>
                                    <th
                                        className="p-0 w-25"
                                        style={{
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            {t("Default text")}
                                        </div>
                                    </th>
                                    <th className="p-0">
                                        <div
                                            className="d-grid__clz"
                                            style={{
                                                gridTemplateColumns:
                                                    "1fr 0.1fr",
                                            }}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                {t("Custom")}
                                            </div>

                                            <StyledIconButton
                                                title={t("Reset")}
                                                className="m-0"
                                                icon="RefreshIcon"
                                                onClick={() => {
                                                    handleResetTranslationOnClickEvent();
                                                }}
                                            >
                                                <RefreshIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </th>
                                    <th style={{ borderRight: "0px" }}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="ml-2 cursor__clz">
                                                {t("Image")}
                                            </div>
                                            <StyledIconButton
                                                title={t("Reset")}
                                                className="m-0"
                                                icon="RefreshIcon"
                                                onClick={() => {
                                                    handleResetImagesOnClickEvent();
                                                }}
                                            >
                                                <RefreshIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                style={{
                                    maxHeight: "100%",
                                }}
                            >
                                {activeTabPrinterOptions ===
                                WITH_EMAIL_PRINTER_OPTIONS_TAB ? (
                                    informationPrinterOptionsWithEmailItems.active ? (
                                        <>
                                            <tr>
                                                <td style={{ width: "25%" }}>
                                                    {informationPrinterOptionsWithEmailItems
                                                        .languages[
                                                        selectedLanguage
                                                    ].defaultContent !==
                                                    undefined
                                                        ? informationPrinterOptionsWithEmailItems
                                                              .languages[
                                                              selectedLanguage
                                                          ].defaultContent
                                                        : null}
                                                </td>
                                                <td>
                                                    <Input
                                                        value={
                                                            informationPrinterOptionsWithEmailItems
                                                                .languages[
                                                                selectedLanguage
                                                            ].content !==
                                                            undefined
                                                                ? informationPrinterOptionsWithEmailItems
                                                                      .languages[
                                                                      selectedLanguage
                                                                  ].content
                                                                : undefined
                                                        }
                                                        onChange={(
                                                            event: React.ChangeEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) => {
                                                            return handleInformationMessageItemInputOnChangeEvent(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        </>
                                    ) : null
                                ) : informationPrinterOptionsWithOutEmailItems.active ? (
                                    <>
                                        <tr>
                                            <td style={{ width: "25%" }}>
                                                {informationPrinterOptionsWithOutEmailItems
                                                    .languages[selectedLanguage]
                                                    .defaultContent !==
                                                undefined
                                                    ? informationPrinterOptionsWithOutEmailItems
                                                          .languages[
                                                          selectedLanguage
                                                      ].defaultContent
                                                    : null}
                                            </td>
                                            <td>
                                                <Input
                                                    value={
                                                        activeTabPrinterOptions ===
                                                        WITH_EMAIL_PRINTER_OPTIONS_TAB
                                                            ? informationPrinterOptionsWithOutEmailItems
                                                                  .languages[
                                                                  selectedLanguage
                                                              ].content !==
                                                              undefined
                                                                ? informationPrinterOptionsWithOutEmailItems
                                                                      .languages[
                                                                      selectedLanguage
                                                                  ].content
                                                                : undefined
                                                            : informationPrinterOptionsWithOutEmailItems
                                                                  .languages[
                                                                  selectedLanguage
                                                              ].content !==
                                                              undefined
                                                            ? informationPrinterOptionsWithOutEmailItems
                                                                  .languages[
                                                                  selectedLanguage
                                                              ].content
                                                            : undefined
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) => {
                                                        return handleInformationMessageItemInputOnChangeEvent(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ) : null}

                                {Object.values(itemsPrinterOptions)
                                    .filter(
                                        (x: any) =>
                                            x.withmail !==
                                            (activeTabPrinterOptions ===
                                            WITHOUT_EMAIL_PRINTER_OPTIONS_TAB
                                                ? true
                                                : false)
                                    )
                                    .map((item: any) => {
                                        return (
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "25%",
                                                    }}
                                                >
                                                    {item.languages[
                                                        selectedLanguage
                                                    ].defaultName !== undefined
                                                        ? item.languages[
                                                              selectedLanguage
                                                          ].defaultName
                                                        : null}
                                                </td>
                                                <td
                                                    className="d-flex"
                                                    style={{
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <Input
                                                        value={
                                                            item.languages[
                                                                selectedLanguage
                                                            ].name !== undefined
                                                                ? item
                                                                      .languages[
                                                                      selectedLanguage
                                                                  ].name
                                                                : null
                                                        }
                                                        disabled={
                                                            item.languages[
                                                                selectedLanguage
                                                            ] !== undefined &&
                                                            item.languages[
                                                                selectedLanguage
                                                            ].isTextVisible ===
                                                                false
                                                        }
                                                        onChange={(
                                                            event: React.ChangeEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) => {
                                                            handleActiveItemInputOnChangeEvent(
                                                                event.target
                                                                    .value,
                                                                item.id
                                                            );
                                                        }}
                                                    />
                                                    {item.languages[
                                                        selectedLanguage
                                                    ] !== undefined &&
                                                    item.languages[
                                                        selectedLanguage
                                                    ].isTextVisible === true ? (
                                                        <EyeIcon
                                                            height={25}
                                                            width={20}
                                                            onClick={() => {
                                                                handleCheckIconOnClickEvent(
                                                                    item.id
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <EyeCrossedIcon
                                                            height={25}
                                                            width={20}
                                                            onClick={() => {
                                                                handleCheckIconOnClickEvent(
                                                                    item.id
                                                                );
                                                            }}
                                                            style={{
                                                                fill: "red",
                                                            }}
                                                        />
                                                    )}
                                                </td>
                                                <td>
                                                    <Uploader
                                                        key={imageKey}
                                                        uploadedFileTitle={
                                                            item.languages[
                                                                selectedLanguage
                                                            ] !== undefined
                                                                ? (item
                                                                      .languages[
                                                                      selectedLanguage
                                                                  ]
                                                                      .content as string)
                                                                : undefined
                                                        }
                                                        id={item.id}
                                                        activeLanguage={
                                                            selectedLanguage
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </AvForm>
                </div>
            </div>
        </div>
    );
}
