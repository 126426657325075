import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    setIsPaymentMessageActive,
    setPaymentMainContent,
    setPaymentConfig,
} from "@store";

import { truncateString } from "@helpers/general";

import { Translation } from "./Translation";

export function MainContentRestoflash(): JSX.Element {
    const { t } = useTranslation();

    const {
        paymentSelectedRegulationModeValue,
        paymentMainContent,
        isPaymentMessageActive,
        isPaymentImageActive,
        paymentInstructionsItem,
    } = useSnapshot(designStore);

    function handleIsPaymentMessageSwitchOnClickEvent() {
        setIsPaymentMessageActive(!isPaymentMessageActive);

        const mainContentItem = [
            ...JSON.parse(JSON.stringify(paymentMainContent)),
        ];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);
            const modifiedLanguage = Object.keys(itemPayment.languages).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...itemPayment.languages[key],
                            paymentInstructions: {
                                ...itemPayment.languages[key]
                                    .paymentInstructions,
                                isTextVisible: !isPaymentMessageActive,
                                active:
                                    !isPaymentMessageActive ===
                                        isPaymentImageActive &&
                                    !isPaymentMessageActive === false
                                        ? false
                                        : true,
                            },
                        },
                    };
                },
                {}
            );

            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages: {
                    ...modifiedLanguage,
                },
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    return (
        <div
            style={{
                top: "363px",
                left: "555px",
                width: "800px",
                height: "500px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #CECECE",
                borderRadius: "9px",
            }}
        >
            <div>
                <div
                    className="text-left px-4 d-flex align-items-center"
                    style={{
                        backgroundColor: "#EDEDED",
                        borderTopLeftRadius: "9px",
                        borderTopRightRadius: "9px",
                        zIndex: 1,
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Payment instruction")}
                </div>
                <div
                    className="p-3"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "180px",
                        gap: "30px",
                    }}
                >
                    <div className="p-4 mt-4 d-flex justify-content-between align-items-center">
                        <div>
                            <div
                                style={{
                                    textAlign: "left",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    color: "#000000",
                                }}
                            >
                                {t("Text")}
                            </div>
                            <div
                                style={{
                                    color: "#B7B7B7",
                                    fontSize: "13px",
                                }}
                            >
                                {t(
                                    truncateString(
                                        paymentInstructionsItem.message,
                                        50
                                    )
                                )}
                            </div>
                        </div>

                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isPaymentMessageActive}
                            onChange={() => {
                                handleIsPaymentMessageSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
            </div>
            {isPaymentMessageActive === true ? <Translation /> : null}
        </div>
    );
}
