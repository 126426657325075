import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setIsPaperlessTicketActive,
    setIsEmailActive,
    setIsConfirmationEmailActive,
    setIsUnauthenticatedEmailActive,
    setItems,
    setFunctionIsPaperlessChecked,
    setIsBackgroundPrinterOptionsConfirmationEmailActive,
    setInformationPrinterOptionsConfirmationEmailItems,
    setInformationPrinterOptionsEntryEmailItems,
    setIsLogoPrinterOptionsConfirmationEmailActive,
    setIsTopBannerPrinterOptionsConfirmationEmailActive,
    setProject,
} from "@store";
import { ConfirmationTicket } from "./ConfirmationTicket";
import { addItemInList, removeItemFromList } from "../Ways/DragList";
export function Ticket(): JSX.Element {
    const { t } = useTranslation();
    const [isModalOpened, setIsModalOpened] = React.useState(false);

    const {
        isPaperlessTicketActive,
        isEmailActive,
        isConfirmationEmailActive,
        isUnauthenticatedEmailActive,
        project,
        isCustomerAccountActive,
    } = useSnapshot(generalConfigStore);

    function setIsPaperlessChecked(localPagesItems: ItemsPagesType) {
        let localPagesItemsUpdated: ItemsPagesType = {
            ...localPagesItems,
        };
        const printerOptionsIndex = Object.keys(
            localPagesItemsUpdated.ways
        ).indexOf("printerOptions");
        if (printerOptionsIndex !== -1) {
            const [removedElement1, newSourceList1] = removeItemFromList(
                Object.values(localPagesItemsUpdated["ways"]),
                printerOptionsIndex
            );

            const removedItem1 = { ...removedElement1 };

            removedItem1.prefix = "elements";

            localPagesItemsUpdated["ways"] = Object.assign(
                {},
                ...newSourceList1.map((element) => ({
                    [element.name]: { ...element },
                }))
            );
            const destinationList1 = Object.values(
                localPagesItemsUpdated["elements"]
            );

            let newDestinationList1 = addItemInList(
                destinationList1,
                destinationList1.length,
                removedItem1
            ) as PageType[];

            localPagesItemsUpdated["elements"] = Object.assign(
                {},
                ...newDestinationList1.map((element, index: number) => ({
                    [element.name]: {
                        ...element,
                        index: index,
                    },
                }))
            );
        }
        localPagesItemsUpdated = {
            ...localPagesItemsUpdated,
            elements: {
                ...localPagesItemsUpdated.elements,
                printerOptions: {
                    ...(localPagesItemsUpdated.elements
                        .printerOptions as PrinterOptionsPageType),
                    isPaperlessTicketActive: !isPaperlessTicketActive,
                    printerOptionsConfig: {
                        ...((localPagesItemsUpdated.elements
                            .printerOptions as PrinterOptionsPageType)
                            .printerOptionsConfig as PrinterOptionsConfigType),
                        isEmailActive: false,
                        isConfirmationEmailActive: false,
                        isUnauthenticatedEmailActive: false,
                    },
                },
            },
        };
        setItems(localPagesItemsUpdated);
        setIsPaperlessTicketActive(!isPaperlessTicketActive);
        setIsEmailActive(false);
        setIsConfirmationEmailActive(false);
        setIsUnauthenticatedEmailActive(false);
    }
    const informationPrinterOptionsEntryEmailItems = ((project.template.pages
        .subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.entryemail;

    const informationPrinterOptionsConfirmationEmailItems = ((project.template
        .pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.confirmationemail;

    function handleIsPaperlessTicketActiveSwitchOnClickEvent() {
        let localPagesItems: ItemsPagesType = {
            ...project.template.pages,
        };
        const printerOptionsIndex = Object.keys(
            localPagesItems.elements
        ).includes("printerOptions")
            ? Object.keys(localPagesItems.elements).indexOf("printerOptions")
            : -1;

        if (!isPaperlessTicketActive === false && printerOptionsIndex === -1) {
            setIsModalOpened(!isModalOpened);

            setFunctionIsPaperlessChecked(() => {
                setIsPaperlessChecked(localPagesItems);
            });
        } else {
            const [removedElement1, newSourceList1] = removeItemFromList(
                Object.values(localPagesItems["elements"]),
                printerOptionsIndex
            );

            const removedItem1 = { ...removedElement1 };

            removedItem1.prefix = "ways";

            localPagesItems["elements"] = Object.assign(
                {},
                ...newSourceList1.map((element) => ({
                    [element.name]: { ...element },
                }))
            );
            const destinationList1 = Object.values(localPagesItems["ways"]);

            let newDestinationList1 = addItemInList(
                destinationList1,
                Object.keys(localPagesItems["ways"]).indexOf("finalMessage") -
                    1,
                removedItem1
            ) as PageType[];

            localPagesItems["ways"] = Object.assign(
                {},
                ...newDestinationList1.map((element, index: number) => ({
                    [element.name]: {
                        ...element,
                        index: index,
                        isPaperlessTicketActive: !isPaperlessTicketActive,
                    },
                }))
            );

            setItems(localPagesItems);

            setIsPaperlessTicketActive(!isPaperlessTicketActive);
        }
    }

    function handleIsEmailActiveSwitchOnClickEvent() {
        let updatedProject = { ...project };
        setIsEmailActive(!isEmailActive);
        if (!isEmailActive === false) {
            setIsConfirmationEmailActive(false);
            updatedProject = {
                ...updatedProject,
                template: {
                    ...updatedProject.template,
                    pages: {
                        ...updatedProject.template.pages,
                        ways: {
                            ...updatedProject.template.pages.ways,
                            printerOptions: {
                                ...updatedProject.template.pages.ways
                                    .printerOptions,
                                printerOptionsConfig: {
                                    ...(updatedProject.template.pages.ways
                                        .printerOptions as PrinterOptionsPageType)
                                        .printerOptionsConfig,
                                    isConfirmationEmailActive: false,
                                },
                            },
                        },
                    },
                },
            };
        }

        updatedProject = {
            ...updatedProject,
            template: {
                ...updatedProject.template,
                pages: {
                    ...updatedProject.template.pages,
                    ways: {
                        ...updatedProject.template.pages.ways,
                        printerOptions: {
                            ...updatedProject.template.pages.ways
                                .printerOptions,
                            printerOptionsConfig: {
                                ...(updatedProject.template.pages.ways
                                    .printerOptions as PrinterOptionsPageType)
                                    .printerOptionsConfig,
                                isEmailActive: !isEmailActive,
                            },
                        },
                    },
                },
            },
        };

        setProject(updatedProject);
    }
    function handleIsConfirmationEmailActiveSwitchOnClickEvent() {
        setIsConfirmationEmailActive(!isConfirmationEmailActive);
        setProject({
            ...project,
            template: {
                ...project.template,
                pages: {
                    ...project.template.pages,
                    ways: {
                        ...project.template.pages.ways,
                        printerOptions: {
                            ...project.template.pages.ways.printerOptions,
                            printerOptionsConfig: {
                                ...(project.template.pages.ways
                                    .printerOptions as PrinterOptionsPageType)
                                    .printerOptionsConfig,
                                isConfirmationEmailActive: !isConfirmationEmailActive,
                            },
                        },
                    },
                },
            },
        });
        if (!isConfirmationEmailActive === false) {
            setIsBackgroundPrinterOptionsConfirmationEmailActive(false);
            setIsTopBannerPrinterOptionsConfirmationEmailActive(false);
            setIsLogoPrinterOptionsConfirmationEmailActive(false);
            setInformationPrinterOptionsConfirmationEmailItems({
                ...informationPrinterOptionsConfirmationEmailItems,
                active: false,
            });
            setInformationPrinterOptionsEntryEmailItems({
                ...informationPrinterOptionsEntryEmailItems,
                active: false,
            });
        }
    }
    function handleIsUnauthenticatedEmailActiveSwitchOnClickEvent() {
        setIsUnauthenticatedEmailActive(!isUnauthenticatedEmailActive);
        setProject({
            ...project,
            template: {
                ...project.template,
                pages: {
                    ...project.template.pages,
                    ways: {
                        ...project.template.pages.ways,
                        printerOptions: {
                            ...project.template.pages.ways.printerOptions,
                            printerOptionsConfig: {
                                ...(project.template.pages.ways
                                    .printerOptions as PrinterOptionsPageType)
                                    .printerOptionsConfig,
                                isUnauthenticatedEmailActive: !isUnauthenticatedEmailActive,
                            },
                        },
                    },
                },
            },
        });
    }
    React.useEffect(() => {
        if (
            isCustomerAccountActive === false &&
            (isEmailActive === true || isConfirmationEmailActive === true)
        ) {
            setIsEmailActive(false);
            setIsConfirmationEmailActive(false);
            if (
                (project.template.pages.ways
                    .printerOptions as PrinterOptionsPageType) !== undefined
            ) {
                setProject({
                    ...project,
                    template: {
                        ...project.template,
                        pages: {
                            ...project.template.pages,
                            ways: {
                                ...project.template.pages.ways,
                                printerOptions: {
                                    ...project.template.pages.ways
                                        .printerOptions,
                                    printerOptionsConfig: {
                                        ...(project.template.pages.ways
                                            .printerOptions as PrinterOptionsPageType)
                                            .printerOptionsConfig,
                                        isEmailActive: false,
                                        isConfirmationEmailActive: false,
                                    },
                                },
                            },
                        },
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomerAccountActive]);
    return (
        <>
            {isModalOpened && (
                <ConfirmationTicket
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                />
            )}
            <div className="d-flex flex-column pr-4">
                <div className="d-flex align-items-center mt-3">
                    <span
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Paperless ticket")}
                    </span>
                </div>

                <div
                    className="d-flex flex-column pl-4"
                    style={{ gap: "10px" }}
                >
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate paperless ticketing")}

                        <ReactSwitch
                            className="ksk_chk_expertTicketDematerialize"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isPaperlessTicketActive}
                            onChange={() => {
                                handleIsPaperlessTicketActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>

                    <hr className="w-100" />
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Authenticated customer with valid e-mail address")}
                    </span>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate the « Send receipt by e-mail » option:")}

                        <ReactSwitch
                            className="ksk_chk_expertAuthSendTicketInEmail"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isEmailActive}
                            onChange={() => {
                                handleIsEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={
                                !(project.template.content
                                    .options as OptionsType).items.filter(
                                    (option) =>
                                        option.title === "Customer account"
                                )[0].active
                                    ? true
                                    : !isPaperlessTicketActive
                            }
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t(
                            "Activate the «Check e-mail address » option before sending the ticket:"
                        )}

                        <ReactSwitch
                            className="ksk_chk_expertAuthCheckEmail"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isConfirmationEmailActive}
                            onChange={() => {
                                handleIsConfirmationEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={
                                !isEmailActive || !isPaperlessTicketActive
                            }
                        />
                    </div>
                    <hr className="w-100" />
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Unauthenticated customer")}
                    </span>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate the “Send receipt by email” option:")}

                        <ReactSwitch
                            className="ksk_chk_expertNotAuthSendEmail"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isUnauthenticatedEmailActive}
                            onChange={() => {
                                handleIsUnauthenticatedEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={!isPaperlessTicketActive}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
